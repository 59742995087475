:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  color: #212529;
  margin-bottom: 1rem;
}

.table td, .table th {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm td, .table-sm th {
  padding: .3rem;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #00000013;
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff;
}

.table-primary tbody + tbody, .table-primary td, .table-primary th, .table-primary thead th {
  border-color: #7abaff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db;
}

.table-secondary tbody + tbody, .table-secondary td, .table-secondary th, .table-secondary thead th {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb;
}

.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table-info tbody + tbody, .table-info td, .table-info th, .table-info thead th {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}

.table-warning tbody + tbody, .table-warning td, .table-warning th, .table-warning thead th {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb;
}

.table-danger tbody + tbody, .table-danger td, .table-danger th, .table-danger thead th {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe;
}

.table-light tbody + tbody, .table-light td, .table-light th, .table-light thead th {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca;
}

.table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
  border-color: #95999c;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > td, .table-active > th, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

input[type="date"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control, input[type="time"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[multiple], select.form-control[size], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.custom-select.is-valid, .was-validated .custom-select:valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem);
}

.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label:before, .was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.custom-select.is-invalid, .was-validated .custom-select:invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem);
}

.custom-select.is-invalid:focus, .was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label:before, .was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select, .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus, .btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus, .btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus, .btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus, .btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus, .btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus, .btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn, .btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-group-sm > .btn, .btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="button"].btn-block, input[type="reset"].btn-block, input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown, .dropleft, .dropright, .dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="top"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: auto;
  position: relative;
}

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  width: 1%;
  min-width: 0;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control, .input-group > .form-control-plaintext + .custom-file, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append, .input-group-prepend {
  display: flex;
}

.input-group-append .btn, .input-group-prepend .btn {
  z-index: 2;
  position: relative;
}

.input-group-append .btn:focus, .input-group-prepend .btn:focus {
  z-index: 3;
}

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="checkbox"], .input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group-lg > .custom-select, .input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .custom-select, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + .5rem + 2px);
}

.input-group-sm > .custom-select, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  z-index: 1;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label, .custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label:before, .custom-control-input[disabled] ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
  left: -2.25rem;
}

.custom-switch .custom-control-label:after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 2.25rem);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input:disabled ~ .custom-file-label, .custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-label:after {
  z-index: 3;
  height: calc(1.5em + .75rem);
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #e9ecef;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  min-height: 1px;
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  border-radius: calc(.25rem - 1px);
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%;
  flex-shrink: 0;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  .card-deck .card {
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  padding-right: .5rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus, a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary.focus, a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff80;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary.focus, a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:focus, a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success.focus, a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #28a74580;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:focus, a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info.focus, a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:focus, a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning.focus, a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:focus, a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger.focus, a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #dc354580;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:focus, a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light.focus, a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:focus, a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark.focus, a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #343a4080;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: .25rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .25rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  flex-basis: 350px;
  font-size: .875rem;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: calc(.3rem - 1px);
  border-bottom-right-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-auto[x-placement^="top"], .bs-tooltip-top {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow, .bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before, .bs-tooltip-top .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-auto[x-placement^="right"], .bs-tooltip-right {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow, .bs-tooltip-right .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before, .bs-tooltip-right .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-auto[x-placement^="bottom"], .bs-tooltip-bottom {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .bs-tooltip-bottom .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="left"], .bs-tooltip-left {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow, .bs-tooltip-left .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before, .bs-tooltip-left .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:after, .popover .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-auto[x-placement^="top"], .bs-popover-top {
  margin-bottom: .5rem;
}

.bs-popover-auto[x-placement^="top"] > .arrow, .bs-popover-top > .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"] > .arrow:before, .bs-popover-top > .arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-auto[x-placement^="top"] > .arrow:after, .bs-popover-top > .arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-auto[x-placement^="right"], .bs-popover-right {
  margin-left: .5rem;
}

.bs-popover-auto[x-placement^="right"] > .arrow, .bs-popover-right > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="right"] > .arrow:before, .bs-popover-right > .arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-auto[x-placement^="right"] > .arrow:after, .bs-popover-right > .arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-auto[x-placement^="bottom"], .bs-popover-bottom {
  margin-top: .5rem;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow, .bs-popover-bottom > .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"] > .arrow:before, .bs-popover-bottom > .arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .bs-popover-bottom > .arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[x-placement^="left"], .bs-popover-left {
  margin-right: .5rem;
}

.bs-popover-auto[x-placement^="left"] > .arrow, .bs-popover-left > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="left"] > .arrow:before, .bs-popover-left > .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-auto[x-placement^="left"] > .arrow:after, .bs-popover-left > .arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: spinner-grow .75s linear infinite;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote, pre {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  thead {
    display: table-header-group;
  }

  img, tr {
    page-break-inside: avoid;
  }

  h2, h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  cursor: help;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="datetime-local"], input[type="month"], input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

code {
  color: #e83e8c;
  word-break: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.col-auto {
  width: auto;
  max-width: none;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-sm-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-md-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-lg-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-xl-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  background-color: #0000;
  margin-bottom: 1rem;
}

.table td, .table th {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm td, .table-sm th {
  padding: .3rem;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  background-color: #00000013;
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > td, .table-active > th, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding-top: .375rem;
  padding-bottom: .375rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .input-group-append > .form-control-plaintext.btn, .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .input-group-append > .form-control-plaintext.btn, .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745cc;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1;
  display: none;
  position: absolute;
  top: 100%;
}

.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
  border-color: #28a745;
}

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label:before, .was-validated .custom-control-input:valid ~ .custom-control-label:before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #28a74540;
}

.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid ~ .custom-file-label:before, .was-validated .custom-file-input:valid ~ .custom-file-label:before {
  border-color: inherit;
}

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545cc;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1;
  display: none;
  position: absolute;
  top: 100%;
}

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
}

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label:before, .was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #dc354540;
}

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid ~ .custom-file-label:before, .was-validated .custom-file-input:invalid ~ .custom-file-label:before {
  border-color: inherit;
}

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  background-image: none;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-primary {
  color: #007bff;
  background-color: #0000;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: #0000;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  background-color: #0000;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: #0000;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: #0000;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: #0000;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: #0000;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  background-color: #0000;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  background-color: #0000;
  font-weight: 400;
}

.btn-link:hover {
  color: #0056b3;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.btn-link.focus, .btn-link:focus {
  box-shadow: none;
  border-color: #0000;
  text-decoration: underline;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn, .btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-group-sm > .btn, .btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="button"].btn-block, input[type="reset"].btn-block, input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

.dropdown, .dropup {
  position: relative;
}

.dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-left: .3em solid;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: 0 auto;
  position: relative;
}

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after {
  margin-left: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  width: 1%;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .custom-file:focus, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label:before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append, .input-group-prepend {
  display: flex;
}

.input-group-append .btn, .input-group-prepend .btn {
  z-index: 2;
  position: relative;
}

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="checkbox"], .input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  min-height: 1.5rem;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-control-input:active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  user-select: none;
  background-color: #dee2e6;
  display: block;
  position: absolute;
  top: .25rem;
  left: 0;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  display: block;
  position: absolute;
  top: .25rem;
  left: 0;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(2.25rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px #00000013, 0 0 5px #80bdff80;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 125%;
}

.custom-file {
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input:focus ~ .custom-file-control:before {
  border-color: #80bdff;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-label {
  z-index: 1;
  height: calc(2.25rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-label:after {
  z-index: 3;
  height: 2.25rem;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  left: auto;
  right: 0;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.card-body {
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-deck {
  flex-direction: column;
  display: flex;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-deck .card {
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
  }
}

.card-group {
  flex-direction: column;
  display: flex;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-header, .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-footer, .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-header, .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-footer, .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: .25rem;
  }

  .card-group > .card:only-child .card-header, .card-group > .card:only-child .card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .card-group > .card:only-child .card-footer, .card-group > .card:only-child .card-img-bottom {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child), .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  background-color: #0062cc;
  text-decoration: none;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  background-color: #545b62;
  text-decoration: none;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  background-color: #1e7e34;
  text-decoration: none;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  background-color: #117a8b;
  text-decoration: none;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #212529;
  background-color: #d39e00;
  text-decoration: none;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  background-color: #bd2130;
  text-decoration: none;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:focus, .badge-light[href]:hover {
  color: #212529;
  background-color: #dae0e5;
  text-decoration: none;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  background-color: #1d2124;
  text-decoration: none;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  margin-bottom: -1px;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  margin-bottom: 0;
}

.list-group-item:focus, .list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:focus, .close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-auto[x-placement^="top"], .bs-tooltip-top {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow, .bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before, .bs-tooltip-top .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-auto[x-placement^="right"], .bs-tooltip-right {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow, .bs-tooltip-right .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before, .bs-tooltip-right .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-auto[x-placement^="bottom"], .bs-tooltip-bottom {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .bs-tooltip-bottom .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="left"], .bs-tooltip-left {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow, .bs-tooltip-left .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before, .bs-tooltip-left .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:after, .popover .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-auto[x-placement^="top"], .bs-popover-top {
  margin-bottom: .5rem;
}

.bs-popover-auto[x-placement^="top"] .arrow, .bs-popover-top .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"] .arrow:after, .bs-popover-auto[x-placement^="top"] .arrow:before, .bs-popover-top .arrow:after, .bs-popover-top .arrow:before {
  border-width: .5rem .5rem 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:before, .bs-popover-top .arrow:before {
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:after, .bs-popover-top .arrow:after {
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-auto[x-placement^="right"], .bs-popover-right {
  margin-left: .5rem;
}

.bs-popover-auto[x-placement^="right"] .arrow, .bs-popover-right .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="right"] .arrow:after, .bs-popover-auto[x-placement^="right"] .arrow:before, .bs-popover-right .arrow:after, .bs-popover-right .arrow:before {
  border-width: .5rem .5rem .5rem 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:before, .bs-popover-right .arrow:before {
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:after, .bs-popover-right .arrow:after {
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-auto[x-placement^="bottom"], .bs-popover-bottom {
  margin-top: .5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow, .bs-popover-bottom .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after, .bs-popover-auto[x-placement^="bottom"] .arrow:before, .bs-popover-bottom .arrow:after, .bs-popover-bottom .arrow:before {
  border-width: 0 .5rem .5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:before, .bs-popover-bottom .arrow:before {
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after, .bs-popover-bottom .arrow:after {
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[x-placement^="left"], .bs-popover-left {
  margin-right: .5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow, .bs-popover-left .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="left"] .arrow:after, .bs-popover-auto[x-placement^="left"] .arrow:before, .bs-popover-left .arrow:after, .bs-popover-left .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow:before, .bs-popover-left .arrow:before {
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-auto[x-placement^="left"] .arrow:after, .bs-popover-left .arrow:after {
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-item {
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
  align-items: center;
  transition: transform .6s, -webkit-transform .6s;
  display: none;
  position: relative;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.active.carousel-item-right, .carousel-item-next {
  transform: translateX(100%);
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
  .active.carousel-item-right, .carousel-item-next {
    transform: translate3d(100%, 0, 0);
  }
}

.active.carousel-item-left, .carousel-item-prev {
  transform: translateX(-100%);
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
  .active.carousel-item-left, .carousel-item-prev {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-next, .carousel-control-prev {
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  width: 30px;
  height: 3px;
  text-indent: -999px;
  background-color: #ffffff80;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
}

.carousel-indicators li:before {
  width: 100%;
  height: 10px;
  content: "";
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 0;
}

.carousel-indicators li:after {
  width: 100%;
  height: 10px;
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  clip-path: none;
  position: static;
  overflow: visible;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote, pre {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  img, tr {
    page-break-inside: avoid;
  }

  h2, h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: FontAwesome;
  src: url("fontawesome-webfont.3981e506.eot");
  src: url("fontawesome-webfont.3981e506.eot#iefix&v=4.7.0") format("embedded-opentype"), url("fontawesome-webfont.58488e7e.woff2") format("woff2"), url("fontawesome-webfont.ed962b83.woff") format("woff"), url("fontawesome-webfont.0caf0c90.ttf") format("truetype"), url("fontawesome-webfont.a9323ae9.svg#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

.fa {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.fa-lg {
  vertical-align: -15%;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571em;
  text-align: center;
}

.fa-ul {
  margin-left: 2.14286em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  width: 2.14286em;
  text-align: center;
  position: absolute;
  top: .142857em;
  left: -2.14286em;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  animation: fa-spin 1s steps(8, end) infinite;
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

:root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-rotate-90 {
  filter: none;
}

.fa-stack {
  width: 2em;
  height: 2em;
  vertical-align: middle;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-close:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-cog:before, .fa-gear:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-repeat:before, .fa-rotate-right:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before, .fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-image:before, .fa-photo:before, .fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before, .fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-cogs:before, .fa-gears:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before, .fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before, .fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before, .fa-users:before {
  content: "";
}

.fa-chain:before, .fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before, .fa-scissors:before {
  content: "";
}

.fa-copy:before, .fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-floppy-o:before, .fa-save:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before, .fa-reorder:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-sort-desc:before, .fa-sort-down:before {
  content: "";
}

.fa-sort-asc:before, .fa-sort-up:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-bolt:before, .fa-flash:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-clipboard:before, .fa-paste:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-chain-broken:before, .fa-unlink:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-caret-square-o-down:before, .fa-toggle-down:before {
  content: "";
}

.fa-caret-square-o-up:before, .fa-toggle-up:before {
  content: "";
}

.fa-caret-square-o-right:before, .fa-toggle-right:before {
  content: "";
}

.fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before, .fa-usd:before {
  content: "";
}

.fa-inr:before, .fa-rupee:before {
  content: "";
}

.fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-krw:before, .fa-won:before {
  content: "";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before, .fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-caret-square-o-left:before, .fa-toggle-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-bank:before, .fa-institution:before, .fa-university:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before, .fa-car:before {
  content: "";
}

.fa-cab:before, .fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-image-o:before, .fa-file-photo-o:before, .fa-file-picture-o:before {
  content: "";
}

.fa-file-archive-o:before, .fa-file-zip-o:before {
  content: "";
}

.fa-file-audio-o:before, .fa-file-sound-o:before {
  content: "";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-ring:before, .fa-life-saver:before, .fa-support:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before, .fa-rebel:before, .fa-resistance:before {
  content: "";
}

.fa-empire:before, .fa-ge:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-hacker-news:before, .fa-y-combinator-square:before, .fa-yc-square:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before, .fa-weixin:before {
  content: "";
}

.fa-paper-plane:before, .fa-send:before {
  content: "";
}

.fa-paper-plane-o:before, .fa-send-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-futbol-o:before, .fa-soccer-ball-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-ils:before, .fa-shekel:before, .fa-sheqel:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before, .fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-bed:before, .fa-hotel:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-y-combinator:before, .fa-yc:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before, .fa-battery-full:before, .fa-battery:before {
  content: "";
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before, .fa-battery-half:before {
  content: "";
}

.fa-battery-1:before, .fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
  content: "";
}

.fa-hand-paper-o:before, .fa-hand-stop-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-television:before, .fa-tv:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before {
  content: "";
}

.fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
  content: "";
}

.fa-fa:before, .fa-font-awesome:before {
  content: "";
}

.fa-handshake-o:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-o:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-book-o:before {
  content: "";
}

.fa-address-card:before, .fa-vcard:before {
  content: "";
}

.fa-address-card-o:before, .fa-vcard-o:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-circle-o:before {
  content: "";
}

.fa-user-o:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "";
}

.fa-drivers-license-o:before, .fa-id-card-o:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-thermometer-4:before, .fa-thermometer-full:before, .fa-thermometer:before {
  content: "";
}

.fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before, .fa-s15:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-times-rectangle-o:before, .fa-window-close-o:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-eercast:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-snowflake-o:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.vendors-loaded {
  color: red;
}

@font-face {
  font-family: Material Design Icons;
  src: url("materialdesignicons-webfont.adec5e5a.eot");
  src: url("materialdesignicons-webfont.adec5e5a.eot#iefix&v=2.8.94") format("embedded-opentype"), url("materialdesignicons-webfont.2e44bc00.woff2") format("woff2"), url("materialdesignicons-webfont.e35fae23.woff") format("woff"), url("materialdesignicons-webfont.8e05fdd8.ttf") format("truetype"), url("materialdesignicons-webfont.36833d23.svg#materialdesigniconsregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

.mdi-set, .mdi:before {
  font: 24px / 1 Material Design Icons;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.mdi-access-point:before {
  content: "";
}

.mdi-access-point-network:before {
  content: "";
}

.mdi-account:before {
  content: "";
}

.mdi-account-alert:before {
  content: "";
}

.mdi-account-box:before {
  content: "";
}

.mdi-account-box-multiple:before {
  content: "盧";
}

.mdi-account-box-outline:before {
  content: "";
}

.mdi-account-card-details:before {
  content: "";
}

.mdi-account-check:before {
  content: "";
}

.mdi-account-child:before {
  content: "愈";
}

.mdi-account-child-circle:before {
  content: "憎";
}

.mdi-account-circle:before {
  content: "";
}

.mdi-account-convert:before {
  content: "";
}

.mdi-account-details:before {
  content: "";
}

.mdi-account-edit:before {
  content: "";
}

.mdi-account-group:before {
  content: "";
}

.mdi-account-heart:before {
  content: "";
}

.mdi-account-key:before {
  content: "";
}

.mdi-account-location:before {
  content: "";
}

.mdi-account-minus:before {
  content: "";
}

.mdi-account-minus-outline:before {
  content: "﫫";
}

.mdi-account-multiple:before {
  content: "";
}

.mdi-account-multiple-check:before {
  content: "";
}

.mdi-account-multiple-minus:before {
  content: "";
}

.mdi-account-multiple-outline:before {
  content: "";
}

.mdi-account-multiple-plus:before {
  content: "";
}

.mdi-account-multiple-plus-outline:before {
  content: "";
}

.mdi-account-network:before {
  content: "";
}

.mdi-account-off:before {
  content: "";
}

.mdi-account-outline:before {
  content: "";
}

.mdi-account-plus:before {
  content: "";
}

.mdi-account-plus-outline:before {
  content: "";
}

.mdi-account-remove:before {
  content: "";
}

.mdi-account-remove-outline:before {
  content: "﫬";
}

.mdi-account-search:before {
  content: "";
}

.mdi-account-search-outline:before {
  content: "老";
}

.mdi-account-settings:before {
  content: "";
}

.mdi-account-star:before {
  content: "";
}

.mdi-account-supervisor:before {
  content: "慠";
}

.mdi-account-supervisor-circle:before {
  content: "懲";
}

.mdi-account-switch:before {
  content: "";
}

.mdi-accusoft:before {
  content: "";
}

.mdi-adjust:before {
  content: "";
}

.mdi-adobe:before {
  content: "蘆";
}

.mdi-air-conditioner:before {
  content: "";
}

.mdi-airballoon:before {
  content: "";
}

.mdi-airplane:before {
  content: "";
}

.mdi-airplane-landing:before {
  content: "";
}

.mdi-airplane-off:before {
  content: "";
}

.mdi-airplane-takeoff:before {
  content: "";
}

.mdi-airplay:before {
  content: "";
}

.mdi-airport:before {
  content: "";
}

.mdi-alarm:before {
  content: "";
}

.mdi-alarm-bell:before {
  content: "";
}

.mdi-alarm-check:before {
  content: "";
}

.mdi-alarm-light:before {
  content: "";
}

.mdi-alarm-multiple:before {
  content: "";
}

.mdi-alarm-off:before {
  content: "";
}

.mdi-alarm-plus:before {
  content: "";
}

.mdi-alarm-snooze:before {
  content: "";
}

.mdi-album:before {
  content: "";
}

.mdi-alert:before {
  content: "";
}

.mdi-alert-box:before {
  content: "";
}

.mdi-alert-circle:before {
  content: "";
}

.mdi-alert-circle-outline:before {
  content: "";
}

.mdi-alert-decagram:before {
  content: "";
}

.mdi-alert-octagon:before {
  content: "";
}

.mdi-alert-octagram:before {
  content: "";
}

.mdi-alert-outline:before {
  content: "";
}

.mdi-alien:before {
  content: "";
}

.mdi-all-inclusive:before {
  content: "";
}

.mdi-alpha:before {
  content: "";
}

.mdi-alpha-a:before {
  content: "A";
}

.mdi-alpha-a-box:before {
  content: "﫭";
}

.mdi-alpha-b:before {
  content: "B";
}

.mdi-alpha-b-box:before {
  content: "﫮";
}

.mdi-alpha-c:before {
  content: "C";
}

.mdi-alpha-c-box:before {
  content: "﫯";
}

.mdi-alpha-d:before {
  content: "D";
}

.mdi-alpha-d-box:before {
  content: "﫰";
}

.mdi-alpha-e:before {
  content: "E";
}

.mdi-alpha-e-box:before {
  content: "﫱";
}

.mdi-alpha-f:before {
  content: "F";
}

.mdi-alpha-f-box:before {
  content: "﫲";
}

.mdi-alpha-g:before {
  content: "G";
}

.mdi-alpha-g-box:before {
  content: "﫳";
}

.mdi-alpha-h:before {
  content: "H";
}

.mdi-alpha-h-box:before {
  content: "﫴";
}

.mdi-alpha-i:before {
  content: "I";
}

.mdi-alpha-i-box:before {
  content: "﫵";
}

.mdi-alpha-j:before {
  content: "J";
}

.mdi-alpha-j-box:before {
  content: "﫶";
}

.mdi-alpha-k:before {
  content: "K";
}

.mdi-alpha-k-box:before {
  content: "﫷";
}

.mdi-alpha-l:before {
  content: "L";
}

.mdi-alpha-l-box:before {
  content: "﫸";
}

.mdi-alpha-m:before {
  content: "M";
}

.mdi-alpha-m-box:before {
  content: "﫹";
}

.mdi-alpha-n:before {
  content: "N";
}

.mdi-alpha-n-box:before {
  content: "﫺";
}

.mdi-alpha-o:before {
  content: "O";
}

.mdi-alpha-o-box:before {
  content: "﫻";
}

.mdi-alpha-p:before {
  content: "P";
}

.mdi-alpha-p-box:before {
  content: "﫼";
}

.mdi-alpha-q:before {
  content: "Q";
}

.mdi-alpha-q-box:before {
  content: "﫽";
}

.mdi-alpha-r:before {
  content: "R";
}

.mdi-alpha-r-box:before {
  content: "﫾";
}

.mdi-alpha-s:before {
  content: "S";
}

.mdi-alpha-s-box:before {
  content: "﫿";
}

.mdi-alpha-t:before {
  content: "T";
}

.mdi-alpha-t-box:before {
  content: "ﬀ";
}

.mdi-alpha-u:before {
  content: "U";
}

.mdi-alpha-u-box:before {
  content: "ﬁ";
}

.mdi-alpha-v:before {
  content: "V";
}

.mdi-alpha-v-box:before {
  content: "ﬂ";
}

.mdi-alpha-w:before {
  content: "W";
}

.mdi-alpha-w-box:before {
  content: "ﬃ";
}

.mdi-alpha-x:before {
  content: "X";
}

.mdi-alpha-x-box:before {
  content: "ﬄ";
}

.mdi-alpha-y:before {
  content: "Y";
}

.mdi-alpha-y-box:before {
  content: "ﬅ";
}

.mdi-alpha-z:before {
  content: "Z";
}

.mdi-alpha-z-box:before {
  content: "ﬆ";
}

.mdi-alphabetical:before {
  content: "";
}

.mdi-altimeter:before {
  content: "";
}

.mdi-amazon:before {
  content: "";
}

.mdi-amazon-alexa:before {
  content: "";
}

.mdi-amazon-drive:before {
  content: "";
}

.mdi-ambulance:before {
  content: "";
}

.mdi-ampersand:before {
  content: "戴";
}

.mdi-amplifier:before {
  content: "";
}

.mdi-anchor:before {
  content: "";
}

.mdi-android:before {
  content: "";
}

.mdi-android-auto:before {
  content: "揄";
}

.mdi-android-debug-bridge:before {
  content: "";
}

.mdi-android-head:before {
  content: "";
}

.mdi-android-studio:before {
  content: "";
}

.mdi-angle-acute:before {
  content: "虜";
}

.mdi-angle-obtuse:before {
  content: "路";
}

.mdi-angle-right:before {
  content: "露";
}

.mdi-angular:before {
  content: "";
}

.mdi-angularjs:before {
  content: "";
}

.mdi-animation:before {
  content: "";
}

.mdi-animation-outline:before {
  content: "搜";
}

.mdi-animation-play:before {
  content: "魯";
}

.mdi-animation-play-outline:before {
  content: "摒";
}

.mdi-anvil:before {
  content: "";
}

.mdi-apple:before {
  content: "";
}

.mdi-apple-finder:before {
  content: "";
}

.mdi-apple-icloud:before {
  content: "";
}

.mdi-apple-ios:before {
  content: "";
}

.mdi-apple-keyboard-caps:before {
  content: "";
}

.mdi-apple-keyboard-command:before {
  content: "";
}

.mdi-apple-keyboard-control:before {
  content: "";
}

.mdi-apple-keyboard-option:before {
  content: "";
}

.mdi-apple-keyboard-shift:before {
  content: "";
}

.mdi-apple-safari:before {
  content: "";
}

.mdi-application:before {
  content: "";
}

.mdi-apps:before {
  content: "";
}

.mdi-arch:before {
  content: "";
}

.mdi-archive:before {
  content: "";
}

.mdi-arrange-bring-forward:before {
  content: "";
}

.mdi-arrange-bring-to-front:before {
  content: "";
}

.mdi-arrange-send-backward:before {
  content: "";
}

.mdi-arrange-send-to-back:before {
  content: "";
}

.mdi-arrow-all:before {
  content: "";
}

.mdi-arrow-bottom-left:before {
  content: "";
}

.mdi-arrow-bottom-left-bold-outline:before {
  content: "禮";
}

.mdi-arrow-bottom-left-thick:before {
  content: "醴";
}

.mdi-arrow-bottom-right:before {
  content: "";
}

.mdi-arrow-bottom-right-bold-outline:before {
  content: "隸";
}

.mdi-arrow-bottom-right-thick:before {
  content: "惡";
}

.mdi-arrow-collapse:before {
  content: "";
}

.mdi-arrow-collapse-all:before {
  content: "";
}

.mdi-arrow-collapse-down:before {
  content: "";
}

.mdi-arrow-collapse-horizontal:before {
  content: "";
}

.mdi-arrow-collapse-left:before {
  content: "";
}

.mdi-arrow-collapse-right:before {
  content: "";
}

.mdi-arrow-collapse-up:before {
  content: "";
}

.mdi-arrow-collapse-vertical:before {
  content: "";
}

.mdi-arrow-decision:before {
  content: "了";
}

.mdi-arrow-decision-auto:before {
  content: "僚";
}

.mdi-arrow-decision-auto-outline:before {
  content: "寮";
}

.mdi-arrow-decision-outline:before {
  content: "尿";
}

.mdi-arrow-down:before {
  content: "";
}

.mdi-arrow-down-bold:before {
  content: "";
}

.mdi-arrow-down-bold-box:before {
  content: "";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "";
}

.mdi-arrow-down-bold-circle:before {
  content: "";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "";
}

.mdi-arrow-down-bold-outline:before {
  content: "料";
}

.mdi-arrow-down-box:before {
  content: "";
}

.mdi-arrow-down-drop-circle:before {
  content: "";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "";
}

.mdi-arrow-down-thick:before {
  content: "";
}

.mdi-arrow-expand:before {
  content: "";
}

.mdi-arrow-expand-all:before {
  content: "";
}

.mdi-arrow-expand-down:before {
  content: "";
}

.mdi-arrow-expand-horizontal:before {
  content: "";
}

.mdi-arrow-expand-left:before {
  content: "";
}

.mdi-arrow-expand-right:before {
  content: "";
}

.mdi-arrow-expand-up:before {
  content: "";
}

.mdi-arrow-expand-vertical:before {
  content: "";
}

.mdi-arrow-left:before {
  content: "";
}

.mdi-arrow-left-bold:before {
  content: "";
}

.mdi-arrow-left-bold-box:before {
  content: "";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "";
}

.mdi-arrow-left-bold-circle:before {
  content: "";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "";
}

.mdi-arrow-left-bold-outline:before {
  content: "樂";
}

.mdi-arrow-left-box:before {
  content: "";
}

.mdi-arrow-left-drop-circle:before {
  content: "";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "";
}

.mdi-arrow-left-right-bold-outline:before {
  content: "燎";
}

.mdi-arrow-left-thick:before {
  content: "";
}

.mdi-arrow-right:before {
  content: "";
}

.mdi-arrow-right-bold:before {
  content: "";
}

.mdi-arrow-right-bold-box:before {
  content: "";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "";
}

.mdi-arrow-right-bold-circle:before {
  content: "";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "";
}

.mdi-arrow-right-bold-outline:before {
  content: "療";
}

.mdi-arrow-right-box:before {
  content: "";
}

.mdi-arrow-right-drop-circle:before {
  content: "";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "";
}

.mdi-arrow-right-thick:before {
  content: "";
}

.mdi-arrow-split-horizontal:before {
  content: "鷺";
}

.mdi-arrow-split-vertical:before {
  content: "碌";
}

.mdi-arrow-top-left:before {
  content: "";
}

.mdi-arrow-top-left-bold-outline:before {
  content: "蓼";
}

.mdi-arrow-top-left-thick:before {
  content: "遼";
}

.mdi-arrow-top-right:before {
  content: "";
}

.mdi-arrow-top-right-bold-outline:before {
  content: "龍";
}

.mdi-arrow-top-right-thick:before {
  content: "暈";
}

.mdi-arrow-up:before {
  content: "";
}

.mdi-arrow-up-bold:before {
  content: "";
}

.mdi-arrow-up-bold-box:before {
  content: "";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "";
}

.mdi-arrow-up-bold-circle:before {
  content: "";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "";
}

.mdi-arrow-up-bold-outline:before {
  content: "阮";
}

.mdi-arrow-up-box:before {
  content: "";
}

.mdi-arrow-up-down-bold-outline:before {
  content: "劉";
}

.mdi-arrow-up-drop-circle:before {
  content: "";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "";
}

.mdi-arrow-up-thick:before {
  content: "";
}

.mdi-artist:before {
  content: "";
}

.mdi-aspect-ratio:before {
  content: "﨣";
}

.mdi-assistant:before {
  content: "";
}

.mdi-asterisk:before {
  content: "";
}

.mdi-at:before {
  content: "";
}

.mdi-atlassian:before {
  content: "";
}

.mdi-atom:before {
  content: "";
}

.mdi-attachment:before {
  content: "";
}

.mdi-audio-video:before {
  content: "祿";
}

.mdi-audiobook:before {
  content: "";
}

.mdi-augmented-reality:before {
  content: "";
}

.mdi-auto-fix:before {
  content: "";
}

.mdi-auto-upload:before {
  content: "";
}

.mdi-autorenew:before {
  content: "";
}

.mdi-av-timer:before {
  content: "";
}

.mdi-axe:before {
  content: "";
}

.mdi-azure:before {
  content: "";
}

.mdi-babel:before {
  content: "﨤";
}

.mdi-baby:before {
  content: "";
}

.mdi-baby-buggy:before {
  content: "";
}

.mdi-backburger:before {
  content: "";
}

.mdi-backspace:before {
  content: "";
}

.mdi-backup-restore:before {
  content: "";
}

.mdi-badminton:before {
  content: "";
}

.mdi-balloon:before {
  content: "逸";
}

.mdi-ballot:before {
  content: "杻";
}

.mdi-ballot-outline:before {
  content: "柳";
}

.mdi-bandcamp:before {
  content: "";
}

.mdi-bank:before {
  content: "";
}

.mdi-bank-transfer:before {
  content: "都";
}

.mdi-bank-transfer-in:before {
  content: "﨧";
}

.mdi-bank-transfer-out:before {
  content: "﨨";
}

.mdi-barcode:before {
  content: "";
}

.mdi-barcode-scan:before {
  content: "";
}

.mdi-barley:before {
  content: "";
}

.mdi-barrel:before {
  content: "";
}

.mdi-baseball:before {
  content: "";
}

.mdi-baseball-bat:before {
  content: "";
}

.mdi-basecamp:before {
  content: "";
}

.mdi-basket:before {
  content: "";
}

.mdi-basket-fill:before {
  content: "";
}

.mdi-basket-unfill:before {
  content: "";
}

.mdi-basketball:before {
  content: "";
}

.mdi-battery:before {
  content: "";
}

.mdi-battery-10:before {
  content: "";
}

.mdi-battery-10-bluetooth:before {
  content: "綠";
}

.mdi-battery-20:before {
  content: "";
}

.mdi-battery-20-bluetooth:before {
  content: "菉";
}

.mdi-battery-30:before {
  content: "";
}

.mdi-battery-30-bluetooth:before {
  content: "錄";
}

.mdi-battery-40:before {
  content: "";
}

.mdi-battery-40-bluetooth:before {
  content: "鹿";
}

.mdi-battery-50:before {
  content: "";
}

.mdi-battery-50-bluetooth:before {
  content: "論";
}

.mdi-battery-60:before {
  content: "";
}

.mdi-battery-60-bluetooth:before {
  content: "壟";
}

.mdi-battery-70:before {
  content: "";
}

.mdi-battery-70-bluetooth:before {
  content: "弄";
}

.mdi-battery-80:before {
  content: "";
}

.mdi-battery-80-bluetooth:before {
  content: "籠";
}

.mdi-battery-90:before {
  content: "";
}

.mdi-battery-90-bluetooth:before {
  content: "聾";
}

.mdi-battery-alert:before {
  content: "";
}

.mdi-battery-alert-bluetooth:before {
  content: "牢";
}

.mdi-battery-bluetooth:before {
  content: "磊";
}

.mdi-battery-bluetooth-variant:before {
  content: "賂";
}

.mdi-battery-charging:before {
  content: "";
}

.mdi-battery-charging-10:before {
  content: "";
}

.mdi-battery-charging-100:before {
  content: "";
}

.mdi-battery-charging-20:before {
  content: "";
}

.mdi-battery-charging-30:before {
  content: "";
}

.mdi-battery-charging-40:before {
  content: "";
}

.mdi-battery-charging-50:before {
  content: "";
}

.mdi-battery-charging-60:before {
  content: "";
}

.mdi-battery-charging-70:before {
  content: "";
}

.mdi-battery-charging-80:before {
  content: "";
}

.mdi-battery-charging-90:before {
  content: "";
}

.mdi-battery-charging-outline:before {
  content: "";
}

.mdi-battery-charging-wireless:before {
  content: "";
}

.mdi-battery-charging-wireless-10:before {
  content: "";
}

.mdi-battery-charging-wireless-20:before {
  content: "";
}

.mdi-battery-charging-wireless-30:before {
  content: "";
}

.mdi-battery-charging-wireless-40:before {
  content: "";
}

.mdi-battery-charging-wireless-50:before {
  content: "";
}

.mdi-battery-charging-wireless-60:before {
  content: "";
}

.mdi-battery-charging-wireless-70:before {
  content: "";
}

.mdi-battery-charging-wireless-80:before {
  content: "";
}

.mdi-battery-charging-wireless-90:before {
  content: "";
}

.mdi-battery-charging-wireless-alert:before {
  content: "";
}

.mdi-battery-charging-wireless-outline:before {
  content: "";
}

.mdi-battery-minus:before {
  content: "";
}

.mdi-battery-negative:before {
  content: "";
}

.mdi-battery-outline:before {
  content: "";
}

.mdi-battery-plus:before {
  content: "";
}

.mdi-battery-positive:before {
  content: "";
}

.mdi-battery-unknown:before {
  content: "";
}

.mdi-battery-unknown-bluetooth:before {
  content: "雷";
}

.mdi-beach:before {
  content: "";
}

.mdi-beaker:before {
  content: "";
}

.mdi-beats:before {
  content: "";
}

.mdi-bed-empty:before {
  content: "";
}

.mdi-beer:before {
  content: "";
}

.mdi-behance:before {
  content: "";
}

.mdi-bell:before {
  content: "";
}

.mdi-bell-off:before {
  content: "";
}

.mdi-bell-off-outline:before {
  content: "敖";
}

.mdi-bell-outline:before {
  content: "";
}

.mdi-bell-plus:before {
  content: "";
}

.mdi-bell-plus-outline:before {
  content: "晴";
}

.mdi-bell-ring:before {
  content: "";
}

.mdi-bell-ring-outline:before {
  content: "";
}

.mdi-bell-sleep:before {
  content: "";
}

.mdi-bell-sleep-outline:before {
  content: "朗";
}

.mdi-beta:before {
  content: "";
}

.mdi-betamax:before {
  content: "流";
}

.mdi-bible:before {
  content: "";
}

.mdi-bike:before {
  content: "";
}

.mdi-bing:before {
  content: "";
}

.mdi-binoculars:before {
  content: "";
}

.mdi-bio:before {
  content: "";
}

.mdi-biohazard:before {
  content: "";
}

.mdi-bitbucket:before {
  content: "";
}

.mdi-bitcoin:before {
  content: "";
}

.mdi-black-mesa:before {
  content: "";
}

.mdi-blackberry:before {
  content: "";
}

.mdi-blender:before {
  content: "";
}

.mdi-blinds:before {
  content: "";
}

.mdi-block-helper:before {
  content: "";
}

.mdi-blogger:before {
  content: "";
}

.mdi-bluetooth:before {
  content: "";
}

.mdi-bluetooth-audio:before {
  content: "";
}

.mdi-bluetooth-connect:before {
  content: "";
}

.mdi-bluetooth-off:before {
  content: "";
}

.mdi-bluetooth-settings:before {
  content: "";
}

.mdi-bluetooth-transfer:before {
  content: "";
}

.mdi-blur:before {
  content: "";
}

.mdi-blur-linear:before {
  content: "";
}

.mdi-blur-off:before {
  content: "";
}

.mdi-blur-radial:before {
  content: "";
}

.mdi-bomb:before {
  content: "";
}

.mdi-bomb-off:before {
  content: "";
}

.mdi-bone:before {
  content: "";
}

.mdi-book:before {
  content: "";
}

.mdi-book-lock:before {
  content: "";
}

.mdi-book-lock-open:before {
  content: "";
}

.mdi-book-minus:before {
  content: "";
}

.mdi-book-multiple:before {
  content: "";
}

.mdi-book-multiple-minus:before {
  content: "望";
}

.mdi-book-multiple-plus:before {
  content: "杖";
}

.mdi-book-multiple-remove:before {
  content: "歹";
}

.mdi-book-multiple-variant:before {
  content: "";
}

.mdi-book-open:before {
  content: "";
}

.mdi-book-open-page-variant:before {
  content: "";
}

.mdi-book-open-variant:before {
  content: "";
}

.mdi-book-plus:before {
  content: "";
}

.mdi-book-remove:before {
  content: "殺";
}

.mdi-book-variant:before {
  content: "";
}

.mdi-bookmark:before {
  content: "";
}

.mdi-bookmark-check:before {
  content: "";
}

.mdi-bookmark-minus:before {
  content: "溜";
}

.mdi-bookmark-minus-outline:before {
  content: "琉";
}

.mdi-bookmark-music:before {
  content: "";
}

.mdi-bookmark-off:before {
  content: "留";
}

.mdi-bookmark-off-outline:before {
  content: "硫";
}

.mdi-bookmark-outline:before {
  content: "";
}

.mdi-bookmark-plus:before {
  content: "";
}

.mdi-bookmark-plus-outline:before {
  content: "";
}

.mdi-bookmark-remove:before {
  content: "";
}

.mdi-boombox:before {
  content: "";
}

.mdi-bootstrap:before {
  content: "";
}

.mdi-border-all:before {
  content: "";
}

.mdi-border-all-variant:before {
  content: "";
}

.mdi-border-bottom:before {
  content: "";
}

.mdi-border-bottom-variant:before {
  content: "";
}

.mdi-border-color:before {
  content: "";
}

.mdi-border-horizontal:before {
  content: "";
}

.mdi-border-inside:before {
  content: "";
}

.mdi-border-left:before {
  content: "";
}

.mdi-border-left-variant:before {
  content: "";
}

.mdi-border-none:before {
  content: "";
}

.mdi-border-none-variant:before {
  content: "";
}

.mdi-border-outside:before {
  content: "";
}

.mdi-border-right:before {
  content: "";
}

.mdi-border-right-variant:before {
  content: "";
}

.mdi-border-style:before {
  content: "";
}

.mdi-border-top:before {
  content: "";
}

.mdi-border-top-variant:before {
  content: "";
}

.mdi-border-vertical:before {
  content: "";
}

.mdi-bottle-wine:before {
  content: "";
}

.mdi-bow-tie:before {
  content: "";
}

.mdi-bowl:before {
  content: "";
}

.mdi-bowling:before {
  content: "";
}

.mdi-box:before {
  content: "";
}

.mdi-box-cutter:before {
  content: "";
}

.mdi-box-shadow:before {
  content: "";
}

.mdi-braille:before {
  content: "紐";
}

.mdi-brain:before {
  content: "類";
}

.mdi-bridge:before {
  content: "";
}

.mdi-briefcase:before {
  content: "";
}

.mdi-briefcase-check:before {
  content: "";
}

.mdi-briefcase-download:before {
  content: "";
}

.mdi-briefcase-edit:before {
  content: "流";
}

.mdi-briefcase-minus:before {
  content: "﨩";
}

.mdi-briefcase-outline:before {
  content: "";
}

.mdi-briefcase-plus:before {
  content: "飯";
}

.mdi-briefcase-remove:before {
  content: "飼";
}

.mdi-briefcase-search:before {
  content: "館";
}

.mdi-briefcase-upload:before {
  content: "";
}

.mdi-brightness-1:before {
  content: "";
}

.mdi-brightness-2:before {
  content: "";
}

.mdi-brightness-3:before {
  content: "";
}

.mdi-brightness-4:before {
  content: "";
}

.mdi-brightness-5:before {
  content: "";
}

.mdi-brightness-6:before {
  content: "";
}

.mdi-brightness-7:before {
  content: "";
}

.mdi-brightness-auto:before {
  content: "";
}

.mdi-broom:before {
  content: "";
}

.mdi-brush:before {
  content: "";
}

.mdi-buddhism:before {
  content: "壘";
}

.mdi-buffer:before {
  content: "";
}

.mdi-bug:before {
  content: "";
}

.mdi-bug-check:before {
  content: "鶴";
}

.mdi-bug-check-outline:before {
  content: "郞";
}

.mdi-bug-outline:before {
  content: "隷";
}

.mdi-bulldozer:before {
  content: "﬇";
}

.mdi-bulletin-board:before {
  content: "";
}

.mdi-bullhorn:before {
  content: "";
}

.mdi-bullhorn-outline:before {
  content: "﬈";
}

.mdi-bullseye:before {
  content: "";
}

.mdi-bullseye-arrow:before {
  content: "";
}

.mdi-bus:before {
  content: "";
}

.mdi-bus-alert:before {
  content: "滛";
}

.mdi-bus-articulated-end:before {
  content: "";
}

.mdi-bus-articulated-front:before {
  content: "";
}

.mdi-bus-clock:before {
  content: "";
}

.mdi-bus-double-decker:before {
  content: "";
}

.mdi-bus-school:before {
  content: "";
}

.mdi-bus-side:before {
  content: "";
}

.mdi-cached:before {
  content: "";
}

.mdi-cake:before {
  content: "";
}

.mdi-cake-layered:before {
  content: "";
}

.mdi-cake-variant:before {
  content: "";
}

.mdi-calculator:before {
  content: "";
}

.mdi-calculator-variant:before {
  content: "滋";
}

.mdi-calendar:before {
  content: "";
}

.mdi-calendar-alert:before {
  content: "侮";
}

.mdi-calendar-blank:before {
  content: "";
}

.mdi-calendar-check:before {
  content: "";
}

.mdi-calendar-clock:before {
  content: "";
}

.mdi-calendar-edit:before {
  content: "";
}

.mdi-calendar-export:before {
  content: "﬉";
}

.mdi-calendar-heart:before {
  content: "六";
}

.mdi-calendar-import:before {
  content: "﬊";
}

.mdi-calendar-multiple:before {
  content: "";
}

.mdi-calendar-multiple-check:before {
  content: "";
}

.mdi-calendar-multiselect:before {
  content: "僧";
}

.mdi-calendar-plus:before {
  content: "";
}

.mdi-calendar-question:before {
  content: "";
}

.mdi-calendar-range:before {
  content: "";
}

.mdi-calendar-remove:before {
  content: "";
}

.mdi-calendar-search:before {
  content: "屢";
}

.mdi-calendar-star:before {
  content: "戮";
}

.mdi-calendar-text:before {
  content: "";
}

.mdi-calendar-today:before {
  content: "";
}

.mdi-calendar-week:before {
  content: "免";
}

.mdi-calendar-week-begin:before {
  content: "勉";
}

.mdi-call-made:before {
  content: "";
}

.mdi-call-merge:before {
  content: "";
}

.mdi-call-missed:before {
  content: "";
}

.mdi-call-received:before {
  content: "";
}

.mdi-call-split:before {
  content: "";
}

.mdi-camcorder:before {
  content: "";
}

.mdi-camcorder-box:before {
  content: "";
}

.mdi-camcorder-box-off:before {
  content: "";
}

.mdi-camcorder-off:before {
  content: "";
}

.mdi-camera:before {
  content: "";
}

.mdi-camera-account:before {
  content: "";
}

.mdi-camera-burst:before {
  content: "";
}

.mdi-camera-enhance:before {
  content: "";
}

.mdi-camera-front:before {
  content: "";
}

.mdi-camera-front-variant:before {
  content: "";
}

.mdi-camera-gopro:before {
  content: "";
}

.mdi-camera-image:before {
  content: "";
}

.mdi-camera-iris:before {
  content: "";
}

.mdi-camera-metering-center:before {
  content: "";
}

.mdi-camera-metering-matrix:before {
  content: "";
}

.mdi-camera-metering-partial:before {
  content: "";
}

.mdi-camera-metering-spot:before {
  content: "";
}

.mdi-camera-off:before {
  content: "";
}

.mdi-camera-party-mode:before {
  content: "";
}

.mdi-camera-rear:before {
  content: "";
}

.mdi-camera-rear-variant:before {
  content: "";
}

.mdi-camera-switch:before {
  content: "";
}

.mdi-camera-timer:before {
  content: "";
}

.mdi-cancel:before {
  content: "";
}

.mdi-candle:before {
  content: "";
}

.mdi-candycane:before {
  content: "";
}

.mdi-cannabis:before {
  content: "";
}

.mdi-caps-lock:before {
  content: "漢";
}

.mdi-car:before {
  content: "";
}

.mdi-car-battery:before {
  content: "";
}

.mdi-car-connected:before {
  content: "";
}

.mdi-car-convertible:before {
  content: "";
}

.mdi-car-estate:before {
  content: "";
}

.mdi-car-hatchback:before {
  content: "";
}

.mdi-car-limousine:before {
  content: "";
}

.mdi-car-pickup:before {
  content: "";
}

.mdi-car-side:before {
  content: "";
}

.mdi-car-sports:before {
  content: "";
}

.mdi-car-wash:before {
  content: "";
}

.mdi-caravan:before {
  content: "";
}

.mdi-cards:before {
  content: "";
}

.mdi-cards-club:before {
  content: "";
}

.mdi-cards-diamond:before {
  content: "";
}

.mdi-cards-heart:before {
  content: "";
}

.mdi-cards-outline:before {
  content: "";
}

.mdi-cards-playing-outline:before {
  content: "";
}

.mdi-cards-spade:before {
  content: "";
}

.mdi-cards-variant:before {
  content: "";
}

.mdi-carrot:before {
  content: "";
}

.mdi-cart:before {
  content: "";
}

.mdi-cart-off:before {
  content: "";
}

.mdi-cart-outline:before {
  content: "";
}

.mdi-cart-plus:before {
  content: "";
}

.mdi-case-sensitive-alt:before {
  content: "";
}

.mdi-cash:before {
  content: "";
}

.mdi-cash-100:before {
  content: "";
}

.mdi-cash-multiple:before {
  content: "";
}

.mdi-cash-refund:before {
  content: "瀞";
}

.mdi-cash-usd:before {
  content: "";
}

.mdi-cassette:before {
  content: "陸";
}

.mdi-cast:before {
  content: "";
}

.mdi-cast-connected:before {
  content: "";
}

.mdi-cast-off:before {
  content: "";
}

.mdi-castle:before {
  content: "";
}

.mdi-cat:before {
  content: "";
}

.mdi-cctv:before {
  content: "";
}

.mdi-ceiling-light:before {
  content: "";
}

.mdi-cellphone:before {
  content: "";
}

.mdi-cellphone-android:before {
  content: "";
}

.mdi-cellphone-arrow-down:before {
  content: "倫";
}

.mdi-cellphone-basic:before {
  content: "";
}

.mdi-cellphone-dock:before {
  content: "";
}

.mdi-cellphone-erase:before {
  content: "樓";
}

.mdi-cellphone-iphone:before {
  content: "";
}

.mdi-cellphone-key:before {
  content: "淚";
}

.mdi-cellphone-link:before {
  content: "";
}

.mdi-cellphone-link-off:before {
  content: "";
}

.mdi-cellphone-lock:before {
  content: "漏";
}

.mdi-cellphone-message:before {
  content: "";
}

.mdi-cellphone-off:before {
  content: "累";
}

.mdi-cellphone-screenshot:before {
  content: "勤";
}

.mdi-cellphone-settings:before {
  content: "";
}

.mdi-cellphone-settings-variant:before {
  content: "縷";
}

.mdi-cellphone-sound:before {
  content: "陋";
}

.mdi-cellphone-text:before {
  content: "";
}

.mdi-cellphone-wireless:before {
  content: "";
}

.mdi-certificate:before {
  content: "";
}

.mdi-chair-school:before {
  content: "";
}

.mdi-chart-arc:before {
  content: "";
}

.mdi-chart-areaspline:before {
  content: "";
}

.mdi-chart-bar:before {
  content: "";
}

.mdi-chart-bar-stacked:before {
  content: "";
}

.mdi-chart-bubble:before {
  content: "";
}

.mdi-chart-donut:before {
  content: "";
}

.mdi-chart-donut-variant:before {
  content: "";
}

.mdi-chart-gantt:before {
  content: "";
}

.mdi-chart-histogram:before {
  content: "";
}

.mdi-chart-line:before {
  content: "";
}

.mdi-chart-line-stacked:before {
  content: "";
}

.mdi-chart-line-variant:before {
  content: "";
}

.mdi-chart-multiline:before {
  content: "";
}

.mdi-chart-pie:before {
  content: "";
}

.mdi-chart-scatterplot-hexbin:before {
  content: "";
}

.mdi-chart-timeline:before {
  content: "";
}

.mdi-check:before {
  content: "";
}

.mdi-check-all:before {
  content: "";
}

.mdi-check-circle:before {
  content: "";
}

.mdi-check-circle-outline:before {
  content: "";
}

.mdi-check-decagram:before {
  content: "";
}

.mdi-check-outline:before {
  content: "";
}

.mdi-checkbook:before {
  content: "煮";
}

.mdi-checkbox-blank:before {
  content: "";
}

.mdi-checkbox-blank-circle:before {
  content: "";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "";
}

.mdi-checkbox-blank-outline:before {
  content: "";
}

.mdi-checkbox-intermediate:before {
  content: "";
}

.mdi-checkbox-marked:before {
  content: "";
}

.mdi-checkbox-marked-circle:before {
  content: "";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "";
}

.mdi-checkbox-marked-outline:before {
  content: "";
}

.mdi-checkbox-multiple-blank:before {
  content: "";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "";
}

.mdi-checkbox-multiple-marked:before {
  content: "";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "";
}

.mdi-checkerboard:before {
  content: "";
}

.mdi-chemical-weapon:before {
  content: "";
}

.mdi-chess-bishop:before {
  content: "";
}

.mdi-chess-king:before {
  content: "";
}

.mdi-chess-knight:before {
  content: "";
}

.mdi-chess-pawn:before {
  content: "";
}

.mdi-chess-queen:before {
  content: "";
}

.mdi-chess-rook:before {
  content: "";
}

.mdi-chevron-double-down:before {
  content: "";
}

.mdi-chevron-double-left:before {
  content: "";
}

.mdi-chevron-double-right:before {
  content: "";
}

.mdi-chevron-double-up:before {
  content: "";
}

.mdi-chevron-down:before {
  content: "";
}

.mdi-chevron-down-box:before {
  content: "崙";
}

.mdi-chevron-down-box-outline:before {
  content: "淪";
}

.mdi-chevron-down-circle:before {
  content: "﬋";
}

.mdi-chevron-down-circle-outline:before {
  content: "﬌";
}

.mdi-chevron-left:before {
  content: "";
}

.mdi-chevron-left-box:before {
  content: "輪";
}

.mdi-chevron-left-box-outline:before {
  content: "律";
}

.mdi-chevron-left-circle:before {
  content: "﬍";
}

.mdi-chevron-left-circle-outline:before {
  content: "﬎";
}

.mdi-chevron-right:before {
  content: "";
}

.mdi-chevron-right-box:before {
  content: "慄";
}

.mdi-chevron-right-box-outline:before {
  content: "栗";
}

.mdi-chevron-right-circle:before {
  content: "﬏";
}

.mdi-chevron-right-circle-outline:before {
  content: "﬐";
}

.mdi-chevron-up:before {
  content: "";
}

.mdi-chevron-up-box:before {
  content: "率";
}

.mdi-chevron-up-box-outline:before {
  content: "隆";
}

.mdi-chevron-up-circle:before {
  content: "﬑";
}

.mdi-chevron-up-circle-outline:before {
  content: "﬒";
}

.mdi-chili-hot:before {
  content: "";
}

.mdi-chili-medium:before {
  content: "";
}

.mdi-chili-mild:before {
  content: "";
}

.mdi-chip:before {
  content: "";
}

.mdi-christianity:before {
  content: "勒";
}

.mdi-church:before {
  content: "";
}

.mdi-circle:before {
  content: "";
}

.mdi-circle-edit-outline:before {
  content: "";
}

.mdi-circle-medium:before {
  content: "利";
}

.mdi-circle-outline:before {
  content: "";
}

.mdi-circle-slice-1:before {
  content: "瞧";
}

.mdi-circle-slice-2:before {
  content: "爵";
}

.mdi-circle-slice-3:before {
  content: "犯";
}

.mdi-circle-slice-4:before {
  content: "猪";
}

.mdi-circle-slice-5:before {
  content: "瑱";
}

.mdi-circle-slice-6:before {
  content: "甆";
}

.mdi-circle-slice-7:before {
  content: "画";
}

.mdi-circle-slice-8:before {
  content: "瘝";
}

.mdi-circle-small:before {
  content: "吏";
}

.mdi-cisco-webex:before {
  content: "";
}

.mdi-city:before {
  content: "";
}

.mdi-city-variant:before {
  content: "卑";
}

.mdi-city-variant-outline:before {
  content: "喝";
}

.mdi-clipboard:before {
  content: "";
}

.mdi-clipboard-account:before {
  content: "";
}

.mdi-clipboard-alert:before {
  content: "";
}

.mdi-clipboard-arrow-down:before {
  content: "";
}

.mdi-clipboard-arrow-left:before {
  content: "";
}

.mdi-clipboard-check:before {
  content: "";
}

.mdi-clipboard-check-outline:before {
  content: "";
}

.mdi-clipboard-flow:before {
  content: "";
}

.mdi-clipboard-outline:before {
  content: "";
}

.mdi-clipboard-plus:before {
  content: "";
}

.mdi-clipboard-pulse:before {
  content: "";
}

.mdi-clipboard-pulse-outline:before {
  content: "";
}

.mdi-clipboard-text:before {
  content: "";
}

.mdi-clipboard-text-outline:before {
  content: "嘆";
}

.mdi-clippy:before {
  content: "";
}

.mdi-clock:before {
  content: "肋";
}

.mdi-clock-alert:before {
  content: "凜";
}

.mdi-clock-alert-outline:before {
  content: "";
}

.mdi-clock-end:before {
  content: "";
}

.mdi-clock-fast:before {
  content: "";
}

.mdi-clock-in:before {
  content: "";
}

.mdi-clock-out:before {
  content: "";
}

.mdi-clock-outline:before {
  content: "";
}

.mdi-clock-start:before {
  content: "";
}

.mdi-close:before {
  content: "";
}

.mdi-close-box:before {
  content: "";
}

.mdi-close-box-outline:before {
  content: "";
}

.mdi-close-circle:before {
  content: "";
}

.mdi-close-circle-outline:before {
  content: "";
}

.mdi-close-network:before {
  content: "";
}

.mdi-close-octagon:before {
  content: "";
}

.mdi-close-octagon-outline:before {
  content: "";
}

.mdi-close-outline:before {
  content: "";
}

.mdi-closed-caption:before {
  content: "";
}

.mdi-cloud:before {
  content: "";
}

.mdi-cloud-alert:before {
  content: "履";
}

.mdi-cloud-braces:before {
  content: "";
}

.mdi-cloud-check:before {
  content: "";
}

.mdi-cloud-circle:before {
  content: "";
}

.mdi-cloud-download:before {
  content: "";
}

.mdi-cloud-off-outline:before {
  content: "";
}

.mdi-cloud-outline:before {
  content: "";
}

.mdi-cloud-print:before {
  content: "";
}

.mdi-cloud-print-outline:before {
  content: "";
}

.mdi-cloud-question:before {
  content: "器";
}

.mdi-cloud-search:before {
  content: "凌";
}

.mdi-cloud-search-outline:before {
  content: "稜";
}

.mdi-cloud-sync:before {
  content: "";
}

.mdi-cloud-tags:before {
  content: "";
}

.mdi-cloud-upload:before {
  content: "";
}

.mdi-clover:before {
  content: "";
}

.mdi-code-array:before {
  content: "";
}

.mdi-code-braces:before {
  content: "";
}

.mdi-code-brackets:before {
  content: "";
}

.mdi-code-equal:before {
  content: "";
}

.mdi-code-greater-than:before {
  content: "";
}

.mdi-code-greater-than-or-equal:before {
  content: "";
}

.mdi-code-less-than:before {
  content: "";
}

.mdi-code-less-than-or-equal:before {
  content: "";
}

.mdi-code-not-equal:before {
  content: "";
}

.mdi-code-not-equal-variant:before {
  content: "";
}

.mdi-code-parentheses:before {
  content: "";
}

.mdi-code-string:before {
  content: "";
}

.mdi-code-tags:before {
  content: "";
}

.mdi-code-tags-check:before {
  content: "";
}

.mdi-codepen:before {
  content: "";
}

.mdi-coffee:before {
  content: "";
}

.mdi-coffee-outline:before {
  content: "";
}

.mdi-coffee-to-go:before {
  content: "";
}

.mdi-cogs:before {
  content: "";
}

.mdi-coin:before {
  content: "";
}

.mdi-coins:before {
  content: "";
}

.mdi-collage:before {
  content: "";
}

.mdi-collapse-all:before {
  content: "瘟";
}

.mdi-collapse-all-outline:before {
  content: "益";
}

.mdi-color-helper:before {
  content: "";
}

.mdi-comment:before {
  content: "";
}

.mdi-comment-account:before {
  content: "";
}

.mdi-comment-account-outline:before {
  content: "";
}

.mdi-comment-alert:before {
  content: "";
}

.mdi-comment-alert-outline:before {
  content: "";
}

.mdi-comment-arrow-left:before {
  content: "易";
}

.mdi-comment-arrow-left-outline:before {
  content: "李";
}

.mdi-comment-arrow-right:before {
  content: "梨";
}

.mdi-comment-arrow-right-outline:before {
  content: "泥";
}

.mdi-comment-check:before {
  content: "";
}

.mdi-comment-check-outline:before {
  content: "";
}

.mdi-comment-eye:before {
  content: "塀";
}

.mdi-comment-eye-outline:before {
  content: "墨";
}

.mdi-comment-multiple:before {
  content: "";
}

.mdi-comment-multiple-outline:before {
  content: "";
}

.mdi-comment-outline:before {
  content: "";
}

.mdi-comment-plus:before {
  content: "理";
}

.mdi-comment-plus-outline:before {
  content: "";
}

.mdi-comment-processing:before {
  content: "";
}

.mdi-comment-processing-outline:before {
  content: "";
}

.mdi-comment-question:before {
  content: "";
}

.mdi-comment-question-outline:before {
  content: "";
}

.mdi-comment-remove:before {
  content: "";
}

.mdi-comment-remove-outline:before {
  content: "";
}

.mdi-comment-search:before {
  content: "層";
}

.mdi-comment-search-outline:before {
  content: "屮";
}

.mdi-comment-text:before {
  content: "";
}

.mdi-comment-text-multiple:before {
  content: "";
}

.mdi-comment-text-multiple-outline:before {
  content: "";
}

.mdi-comment-text-outline:before {
  content: "";
}

.mdi-compare:before {
  content: "";
}

.mdi-compass:before {
  content: "";
}

.mdi-compass-outline:before {
  content: "";
}

.mdi-console:before {
  content: "";
}

.mdi-console-line:before {
  content: "";
}

.mdi-console-network:before {
  content: "";
}

.mdi-contact-mail:before {
  content: "";
}

.mdi-contacts:before {
  content: "";
}

.mdi-contain:before {
  content: "悔";
}

.mdi-contain-end:before {
  content: "慨";
}

.mdi-contain-start:before {
  content: "憎";
}

.mdi-content-copy:before {
  content: "";
}

.mdi-content-cut:before {
  content: "";
}

.mdi-content-duplicate:before {
  content: "";
}

.mdi-content-paste:before {
  content: "";
}

.mdi-content-save:before {
  content: "";
}

.mdi-content-save-all:before {
  content: "";
}

.mdi-content-save-outline:before {
  content: "";
}

.mdi-content-save-settings:before {
  content: "";
}

.mdi-content-save-settings-outline:before {
  content: "ﬓ";
}

.mdi-contrast:before {
  content: "";
}

.mdi-contrast-box:before {
  content: "";
}

.mdi-contrast-circle:before {
  content: "";
}

.mdi-cookie:before {
  content: "";
}

.mdi-copyright:before {
  content: "";
}

.mdi-cordova:before {
  content: "綾";
}

.mdi-corn:before {
  content: "";
}

.mdi-counter:before {
  content: "";
}

.mdi-cow:before {
  content: "";
}

.mdi-crane:before {
  content: "";
}

.mdi-creation:before {
  content: "";
}

.mdi-credit-card:before {
  content: "";
}

.mdi-credit-card-multiple:before {
  content: "";
}

.mdi-credit-card-off:before {
  content: "";
}

.mdi-credit-card-plus:before {
  content: "";
}

.mdi-credit-card-refund:before {
  content: "盛";
}

.mdi-credit-card-scan:before {
  content: "";
}

.mdi-credit-card-settings:before {
  content: "";
}

.mdi-crop:before {
  content: "";
}

.mdi-crop-free:before {
  content: "";
}

.mdi-crop-landscape:before {
  content: "";
}

.mdi-crop-portrait:before {
  content: "";
}

.mdi-crop-rotate:before {
  content: "";
}

.mdi-crop-square:before {
  content: "";
}

.mdi-crosshairs:before {
  content: "";
}

.mdi-crosshairs-gps:before {
  content: "";
}

.mdi-crown:before {
  content: "";
}

.mdi-cryengine:before {
  content: "菱";
}

.mdi-crystal-ball:before {
  content: "ﬔ";
}

.mdi-cube:before {
  content: "";
}

.mdi-cube-outline:before {
  content: "";
}

.mdi-cube-send:before {
  content: "";
}

.mdi-cube-unfolded:before {
  content: "";
}

.mdi-cup:before {
  content: "";
}

.mdi-cup-off:before {
  content: "";
}

.mdi-cup-water:before {
  content: "";
}

.mdi-cupcake:before {
  content: "陵";
}

.mdi-curling:before {
  content: "";
}

.mdi-currency-bdt:before {
  content: "";
}

.mdi-currency-btc:before {
  content: "";
}

.mdi-currency-chf:before {
  content: "";
}

.mdi-currency-cny:before {
  content: "";
}

.mdi-currency-eth:before {
  content: "";
}

.mdi-currency-eur:before {
  content: "";
}

.mdi-currency-gbp:before {
  content: "";
}

.mdi-currency-inr:before {
  content: "";
}

.mdi-currency-jpy:before {
  content: "";
}

.mdi-currency-krw:before {
  content: "";
}

.mdi-currency-kzt:before {
  content: "";
}

.mdi-currency-ngn:before {
  content: "";
}

.mdi-currency-php:before {
  content: "痢";
}

.mdi-currency-rub:before {
  content: "";
}

.mdi-currency-sign:before {
  content: "";
}

.mdi-currency-try:before {
  content: "";
}

.mdi-currency-twd:before {
  content: "";
}

.mdi-currency-usd:before {
  content: "";
}

.mdi-currency-usd-off:before {
  content: "";
}

.mdi-current-ac:before {
  content: "讀";
}

.mdi-current-dc:before {
  content: "拏";
}

.mdi-cursor-default:before {
  content: "";
}

.mdi-cursor-default-outline:before {
  content: "";
}

.mdi-cursor-move:before {
  content: "";
}

.mdi-cursor-pointer:before {
  content: "";
}

.mdi-cursor-text:before {
  content: "";
}

.mdi-database:before {
  content: "";
}

.mdi-database-check:before {
  content: "直";
}

.mdi-database-export:before {
  content: "諾";
}

.mdi-database-import:before {
  content: "樂";
}

.mdi-database-lock:before {
  content: "睊";
}

.mdi-database-minus:before {
  content: "";
}

.mdi-database-plus:before {
  content: "";
}

.mdi-database-search:before {
  content: "";
}

.mdi-death-star:before {
  content: "";
}

.mdi-death-star-variant:before {
  content: "";
}

.mdi-debian:before {
  content: "";
}

.mdi-debug-step-into:before {
  content: "";
}

.mdi-debug-step-out:before {
  content: "";
}

.mdi-debug-step-over:before {
  content: "";
}

.mdi-decagram:before {
  content: "";
}

.mdi-decagram-outline:before {
  content: "";
}

.mdi-decimal-decrease:before {
  content: "";
}

.mdi-decimal-increase:before {
  content: "";
}

.mdi-delete:before {
  content: "";
}

.mdi-delete-circle:before {
  content: "";
}

.mdi-delete-empty:before {
  content: "";
}

.mdi-delete-forever:before {
  content: "";
}

.mdi-delete-outline:before {
  content: "罹";
}

.mdi-delete-restore:before {
  content: "";
}

.mdi-delete-sweep:before {
  content: "";
}

.mdi-delete-variant:before {
  content: "";
}

.mdi-delta:before {
  content: "";
}

.mdi-desk-lamp:before {
  content: "丹";
}

.mdi-deskphone:before {
  content: "";
}

.mdi-desktop-classic:before {
  content: "";
}

.mdi-desktop-mac:before {
  content: "";
}

.mdi-desktop-mac-dashboard:before {
  content: "裏";
}

.mdi-desktop-tower:before {
  content: "";
}

.mdi-desktop-tower-monitor:before {
  content: "着";
}

.mdi-details:before {
  content: "";
}

.mdi-developer-board:before {
  content: "";
}

.mdi-deviantart:before {
  content: "";
}

.mdi-dialpad:before {
  content: "";
}

.mdi-diamond:before {
  content: "";
}

.mdi-dice-1:before {
  content: "";
}

.mdi-dice-2:before {
  content: "";
}

.mdi-dice-3:before {
  content: "";
}

.mdi-dice-4:before {
  content: "";
}

.mdi-dice-5:before {
  content: "";
}

.mdi-dice-6:before {
  content: "";
}

.mdi-dice-d10:before {
  content: "";
}

.mdi-dice-d12:before {
  content: "";
}

.mdi-dice-d20:before {
  content: "";
}

.mdi-dice-d4:before {
  content: "";
}

.mdi-dice-d6:before {
  content: "";
}

.mdi-dice-d8:before {
  content: "";
}

.mdi-dice-multiple:before {
  content: "";
}

.mdi-dictionary:before {
  content: "";
}

.mdi-dip-switch:before {
  content: "";
}

.mdi-directions:before {
  content: "";
}

.mdi-directions-fork:before {
  content: "";
}

.mdi-disc:before {
  content: "";
}

.mdi-disc-alert:before {
  content: "";
}

.mdi-disc-player:before {
  content: "寧";
}

.mdi-discord:before {
  content: "";
}

.mdi-dishwasher:before {
  content: "磌";
}

.mdi-disqus:before {
  content: "";
}

.mdi-disqus-outline:before {
  content: "";
}

.mdi-division:before {
  content: "";
}

.mdi-division-box:before {
  content: "";
}

.mdi-dlna:before {
  content: "懲";
}

.mdi-dna:before {
  content: "";
}

.mdi-dns:before {
  content: "";
}

.mdi-do-not-disturb:before {
  content: "";
}

.mdi-do-not-disturb-off:before {
  content: "";
}

.mdi-docker:before {
  content: "";
}

.mdi-doctor:before {
  content: "敏";
}

.mdi-dog:before {
  content: "既";
}

.mdi-dog-service:before {
  content: "窱";
}

.mdi-dog-side:before {
  content: "暑";
}

.mdi-dolby:before {
  content: "";
}

.mdi-domain:before {
  content: "";
}

.mdi-donkey:before {
  content: "";
}

.mdi-door:before {
  content: "";
}

.mdi-door-closed:before {
  content: "";
}

.mdi-door-open:before {
  content: "";
}

.mdi-doorbell-video:before {
  content: "";
}

.mdi-dot-net:before {
  content: "節";
}

.mdi-dots-horizontal:before {
  content: "";
}

.mdi-dots-horizontal-circle:before {
  content: "";
}

.mdi-dots-vertical:before {
  content: "";
}

.mdi-dots-vertical-circle:before {
  content: "";
}

.mdi-douban:before {
  content: "";
}

.mdi-download:before {
  content: "";
}

.mdi-download-multiple:before {
  content: "裡";
}

.mdi-download-network:before {
  content: "";
}

.mdi-drag:before {
  content: "";
}

.mdi-drag-horizontal:before {
  content: "";
}

.mdi-drag-vertical:before {
  content: "";
}

.mdi-drawing:before {
  content: "";
}

.mdi-drawing-box:before {
  content: "";
}

.mdi-dribbble:before {
  content: "";
}

.mdi-dribbble-box:before {
  content: "";
}

.mdi-drone:before {
  content: "";
}

.mdi-dropbox:before {
  content: "";
}

.mdi-drupal:before {
  content: "";
}

.mdi-duck:before {
  content: "";
}

.mdi-dumbbell:before {
  content: "";
}

.mdi-ear-hearing:before {
  content: "";
}

.mdi-ear-hearing-off:before {
  content: "梅";
}

.mdi-earth:before {
  content: "";
}

.mdi-earth-box:before {
  content: "";
}

.mdi-earth-box-off:before {
  content: "";
}

.mdi-earth-off:before {
  content: "";
}

.mdi-edge:before {
  content: "";
}

.mdi-egg:before {
  content: "类";
}

.mdi-egg-easter:before {
  content: "絛";
}

.mdi-eight-track:before {
  content: "里";
}

.mdi-eject:before {
  content: "";
}

.mdi-elephant:before {
  content: "";
}

.mdi-elevation-decline:before {
  content: "";
}

.mdi-elevation-rise:before {
  content: "";
}

.mdi-elevator:before {
  content: "";
}

.mdi-email:before {
  content: "";
}

.mdi-email-alert:before {
  content: "";
}

.mdi-email-check:before {
  content: "練";
}

.mdi-email-check-outline:before {
  content: "缾";
}

.mdi-email-lock:before {
  content: "";
}

.mdi-email-open:before {
  content: "";
}

.mdi-email-open-outline:before {
  content: "";
}

.mdi-email-outline:before {
  content: "";
}

.mdi-email-plus:before {
  content: "離";
}

.mdi-email-plus-outline:before {
  content: "匿";
}

.mdi-email-search:before {
  content: "怒";
}

.mdi-email-search-outline:before {
  content: "率";
}

.mdi-email-variant:before {
  content: "";
}

.mdi-ember:before {
  content: "ﬕ";
}

.mdi-emby:before {
  content: "";
}

.mdi-emoticon:before {
  content: "";
}

.mdi-emoticon-cool:before {
  content: "";
}

.mdi-emoticon-dead:before {
  content: "";
}

.mdi-emoticon-devil:before {
  content: "";
}

.mdi-emoticon-excited:before {
  content: "";
}

.mdi-emoticon-happy:before {
  content: "";
}

.mdi-emoticon-neutral:before {
  content: "";
}

.mdi-emoticon-poop:before {
  content: "";
}

.mdi-emoticon-sad:before {
  content: "";
}

.mdi-emoticon-tongue:before {
  content: "";
}

.mdi-engine:before {
  content: "";
}

.mdi-engine-off:before {
  content: "海";
}

.mdi-engine-off-outline:before {
  content: "渚";
}

.mdi-engine-outline:before {
  content: "";
}

.mdi-equal:before {
  content: "";
}

.mdi-equal-box:before {
  content: "";
}

.mdi-eraser:before {
  content: "";
}

.mdi-eraser-variant:before {
  content: "";
}

.mdi-escalator:before {
  content: "";
}

.mdi-et:before {
  content: "者";
}

.mdi-ethereum:before {
  content: "";
}

.mdi-ethernet:before {
  content: "";
}

.mdi-ethernet-cable:before {
  content: "";
}

.mdi-ethernet-cable-off:before {
  content: "";
}

.mdi-etsy:before {
  content: "";
}

.mdi-ev-station:before {
  content: "";
}

.mdi-eventbrite:before {
  content: "";
}

.mdi-evernote:before {
  content: "";
}

.mdi-exclamation:before {
  content: "";
}

.mdi-exit-run:before {
  content: "漢";
}

.mdi-exit-to-app:before {
  content: "";
}

.mdi-expand-all:before {
  content: "荒";
}

.mdi-expand-all-outline:before {
  content: "華";
}

.mdi-exponent:before {
  content: "異";
}

.mdi-exponent-box:before {
  content: "北";
}

.mdi-export:before {
  content: "";
}

.mdi-eye:before {
  content: "";
}

.mdi-eye-off:before {
  content: "";
}

.mdi-eye-off-outline:before {
  content: "";
}

.mdi-eye-outline:before {
  content: "";
}

.mdi-eye-plus:before {
  content: "";
}

.mdi-eye-plus-outline:before {
  content: "";
}

.mdi-eye-settings:before {
  content: "";
}

.mdi-eye-settings-outline:before {
  content: "";
}

.mdi-eyedropper:before {
  content: "";
}

.mdi-eyedropper-variant:before {
  content: "";
}

.mdi-face:before {
  content: "";
}

.mdi-face-profile:before {
  content: "";
}

.mdi-facebook:before {
  content: "";
}

.mdi-facebook-box:before {
  content: "";
}

.mdi-facebook-messenger:before {
  content: "";
}

.mdi-facebook-workplace:before {
  content: "ﬖ";
}

.mdi-factory:before {
  content: "";
}

.mdi-fan:before {
  content: "";
}

.mdi-fan-off:before {
  content: "";
}

.mdi-fast-forward:before {
  content: "";
}

.mdi-fast-forward-outline:before {
  content: "";
}

.mdi-fax:before {
  content: "";
}

.mdi-feather:before {
  content: "";
}

.mdi-feature-search:before {
  content: "煮";
}

.mdi-feature-search-outline:before {
  content: "爫";
}

.mdi-fedora:before {
  content: "";
}

.mdi-ferry:before {
  content: "";
}

.mdi-file:before {
  content: "";
}

.mdi-file-account:before {
  content: "";
}

.mdi-file-alert:before {
  content: "琢";
}

.mdi-file-alert-outline:before {
  content: "碑";
}

.mdi-file-cabinet:before {
  content: "蝹";
}

.mdi-file-chart:before {
  content: "";
}

.mdi-file-check:before {
  content: "";
}

.mdi-file-cloud:before {
  content: "";
}

.mdi-file-compare:before {
  content: "";
}

.mdi-file-delimited:before {
  content: "";
}

.mdi-file-document:before {
  content: "";
}

.mdi-file-document-box:before {
  content: "";
}

.mdi-file-document-box-multiple:before {
  content: "襁";
}

.mdi-file-document-box-multiple-outline:before {
  content: "覆";
}

.mdi-file-document-box-outline:before {
  content: "溺";
}

.mdi-file-document-outline:before {
  content: "吝";
}

.mdi-file-download:before {
  content: "磻";
}

.mdi-file-download-outline:before {
  content: "便";
}

.mdi-file-excel:before {
  content: "";
}

.mdi-file-excel-box:before {
  content: "";
}

.mdi-file-export:before {
  content: "";
}

.mdi-file-find:before {
  content: "";
}

.mdi-file-hidden:before {
  content: "";
}

.mdi-file-image:before {
  content: "";
}

.mdi-file-import:before {
  content: "";
}

.mdi-file-lock:before {
  content: "";
}

.mdi-file-move:before {
  content: "視";
}

.mdi-file-multiple:before {
  content: "";
}

.mdi-file-music:before {
  content: "";
}

.mdi-file-outline:before {
  content: "";
}

.mdi-file-pdf:before {
  content: "";
}

.mdi-file-pdf-box:before {
  content: "";
}

.mdi-file-percent:before {
  content: "";
}

.mdi-file-plus:before {
  content: "";
}

.mdi-file-powerpoint:before {
  content: "";
}

.mdi-file-powerpoint-box:before {
  content: "";
}

.mdi-file-presentation-box:before {
  content: "";
}

.mdi-file-question:before {
  content: "";
}

.mdi-file-replace:before {
  content: "ﬗ";
}

.mdi-file-replace-outline:before {
  content: "﬘";
}

.mdi-file-restore:before {
  content: "";
}

.mdi-file-send:before {
  content: "";
}

.mdi-file-tree:before {
  content: "";
}

.mdi-file-undo:before {
  content: "";
}

.mdi-file-upload:before {
  content: "社";
}

.mdi-file-upload-outline:before {
  content: "祉";
}

.mdi-file-video:before {
  content: "";
}

.mdi-file-word:before {
  content: "";
}

.mdi-file-word-box:before {
  content: "";
}

.mdi-file-xml:before {
  content: "";
}

.mdi-film:before {
  content: "";
}

.mdi-filmstrip:before {
  content: "";
}

.mdi-filmstrip-off:before {
  content: "";
}

.mdi-filter:before {
  content: "";
}

.mdi-filter-outline:before {
  content: "";
}

.mdi-filter-remove:before {
  content: "";
}

.mdi-filter-remove-outline:before {
  content: "";
}

.mdi-filter-variant:before {
  content: "";
}

.mdi-finance:before {
  content: "";
}

.mdi-find-replace:before {
  content: "";
}

.mdi-fingerprint:before {
  content: "";
}

.mdi-fire:before {
  content: "";
}

.mdi-fire-truck:before {
  content: "";
}

.mdi-firebase:before {
  content: "復";
}

.mdi-firefox:before {
  content: "";
}

.mdi-fish:before {
  content: "";
}

.mdi-flag:before {
  content: "";
}

.mdi-flag-checkered:before {
  content: "";
}

.mdi-flag-outline:before {
  content: "";
}

.mdi-flag-triangle:before {
  content: "";
}

.mdi-flag-variant:before {
  content: "";
}

.mdi-flag-variant-outline:before {
  content: "";
}

.mdi-flash:before {
  content: "";
}

.mdi-flash-auto:before {
  content: "";
}

.mdi-flash-circle:before {
  content: "";
}

.mdi-flash-off:before {
  content: "";
}

.mdi-flash-outline:before {
  content: "";
}

.mdi-flash-red-eye:before {
  content: "";
}

.mdi-flashlight:before {
  content: "";
}

.mdi-flashlight-off:before {
  content: "";
}

.mdi-flask:before {
  content: "";
}

.mdi-flask-empty:before {
  content: "";
}

.mdi-flask-empty-outline:before {
  content: "";
}

.mdi-flask-outline:before {
  content: "";
}

.mdi-flattr:before {
  content: "";
}

.mdi-flip-to-back:before {
  content: "";
}

.mdi-flip-to-front:before {
  content: "";
}

.mdi-floor-lamp:before {
  content: "";
}

.mdi-floor-plan:before {
  content: "";
}

.mdi-floppy:before {
  content: "";
}

.mdi-floppy-variant:before {
  content: "燐";
}

.mdi-flower:before {
  content: "";
}

.mdi-flower-outline:before {
  content: "璘";
}

.mdi-flower-tulip:before {
  content: "藺";
}

.mdi-flower-tulip-outline:before {
  content: "隣";
}

.mdi-folder:before {
  content: "";
}

.mdi-folder-account:before {
  content: "";
}

.mdi-folder-clock:before {
  content: "調";
}

.mdi-folder-clock-outline:before {
  content: "諸";
}

.mdi-folder-download:before {
  content: "";
}

.mdi-folder-edit:before {
  content: "";
}

.mdi-folder-google-drive:before {
  content: "";
}

.mdi-folder-image:before {
  content: "";
}

.mdi-folder-key:before {
  content: "";
}

.mdi-folder-key-network:before {
  content: "";
}

.mdi-folder-lock:before {
  content: "";
}

.mdi-folder-lock-open:before {
  content: "";
}

.mdi-folder-move:before {
  content: "";
}

.mdi-folder-multiple:before {
  content: "";
}

.mdi-folder-multiple-image:before {
  content: "";
}

.mdi-folder-multiple-outline:before {
  content: "";
}

.mdi-folder-network:before {
  content: "";
}

.mdi-folder-open:before {
  content: "";
}

.mdi-folder-outline:before {
  content: "";
}

.mdi-folder-plus:before {
  content: "";
}

.mdi-folder-remove:before {
  content: "";
}

.mdi-folder-search:before {
  content: "不";
}

.mdi-folder-search-outline:before {
  content: "泌";
}

.mdi-folder-star:before {
  content: "";
}

.mdi-folder-upload:before {
  content: "";
}

.mdi-font-awesome:before {
  content: "";
}

.mdi-food:before {
  content: "";
}

.mdi-food-apple:before {
  content: "";
}

.mdi-food-croissant:before {
  content: "";
}

.mdi-food-fork-drink:before {
  content: "";
}

.mdi-food-off:before {
  content: "";
}

.mdi-food-variant:before {
  content: "";
}

.mdi-football:before {
  content: "";
}

.mdi-football-australian:before {
  content: "";
}

.mdi-football-helmet:before {
  content: "";
}

.mdi-forklift:before {
  content: "";
}

.mdi-format-align-bottom:before {
  content: "";
}

.mdi-format-align-center:before {
  content: "";
}

.mdi-format-align-justify:before {
  content: "";
}

.mdi-format-align-left:before {
  content: "";
}

.mdi-format-align-middle:before {
  content: "";
}

.mdi-format-align-right:before {
  content: "";
}

.mdi-format-align-top:before {
  content: "";
}

.mdi-format-annotation-minus:before {
  content: "請";
}

.mdi-format-annotation-plus:before {
  content: "";
}

.mdi-format-bold:before {
  content: "";
}

.mdi-format-clear:before {
  content: "";
}

.mdi-format-color-fill:before {
  content: "";
}

.mdi-format-color-text:before {
  content: "";
}

.mdi-format-columns:before {
  content: "";
}

.mdi-format-float-center:before {
  content: "";
}

.mdi-format-float-left:before {
  content: "";
}

.mdi-format-float-none:before {
  content: "";
}

.mdi-format-float-right:before {
  content: "";
}

.mdi-format-font:before {
  content: "";
}

.mdi-format-font-size-decrease:before {
  content: "鱗";
}

.mdi-format-font-size-increase:before {
  content: "麟";
}

.mdi-format-header-1:before {
  content: "";
}

.mdi-format-header-2:before {
  content: "";
}

.mdi-format-header-3:before {
  content: "";
}

.mdi-format-header-4:before {
  content: "";
}

.mdi-format-header-5:before {
  content: "";
}

.mdi-format-header-6:before {
  content: "";
}

.mdi-format-header-decrease:before {
  content: "";
}

.mdi-format-header-equal:before {
  content: "";
}

.mdi-format-header-increase:before {
  content: "";
}

.mdi-format-header-pound:before {
  content: "";
}

.mdi-format-horizontal-align-center:before {
  content: "";
}

.mdi-format-horizontal-align-left:before {
  content: "";
}

.mdi-format-horizontal-align-right:before {
  content: "";
}

.mdi-format-indent-decrease:before {
  content: "";
}

.mdi-format-indent-increase:before {
  content: "";
}

.mdi-format-italic:before {
  content: "";
}

.mdi-format-letter-case:before {
  content: "﬙";
}

.mdi-format-letter-case-lower:before {
  content: "﬚";
}

.mdi-format-letter-case-upper:before {
  content: "﬛";
}

.mdi-format-line-spacing:before {
  content: "";
}

.mdi-format-line-style:before {
  content: "";
}

.mdi-format-line-weight:before {
  content: "";
}

.mdi-format-list-bulleted:before {
  content: "";
}

.mdi-format-list-bulleted-type:before {
  content: "";
}

.mdi-format-list-checkbox:before {
  content: "數";
}

.mdi-format-list-checks:before {
  content: "";
}

.mdi-format-list-numbers:before {
  content: "";
}

.mdi-format-page-break:before {
  content: "";
}

.mdi-format-paint:before {
  content: "";
}

.mdi-format-paragraph:before {
  content: "";
}

.mdi-format-pilcrow:before {
  content: "";
}

.mdi-format-quote-close:before {
  content: "";
}

.mdi-format-quote-open:before {
  content: "";
}

.mdi-format-rotate-90:before {
  content: "";
}

.mdi-format-section:before {
  content: "";
}

.mdi-format-size:before {
  content: "";
}

.mdi-format-strikethrough:before {
  content: "";
}

.mdi-format-strikethrough-variant:before {
  content: "";
}

.mdi-format-subscript:before {
  content: "";
}

.mdi-format-superscript:before {
  content: "";
}

.mdi-format-text:before {
  content: "";
}

.mdi-format-textdirection-l-to-r:before {
  content: "";
}

.mdi-format-textdirection-r-to-l:before {
  content: "";
}

.mdi-format-title:before {
  content: "";
}

.mdi-format-underline:before {
  content: "";
}

.mdi-format-vertical-align-bottom:before {
  content: "";
}

.mdi-format-vertical-align-center:before {
  content: "";
}

.mdi-format-vertical-align-top:before {
  content: "";
}

.mdi-format-wrap-inline:before {
  content: "";
}

.mdi-format-wrap-square:before {
  content: "";
}

.mdi-format-wrap-tight:before {
  content: "";
}

.mdi-format-wrap-top-bottom:before {
  content: "";
}

.mdi-forum:before {
  content: "";
}

.mdi-forum-outline:before {
  content: "";
}

.mdi-forward:before {
  content: "";
}

.mdi-fountain:before {
  content: "索";
}

.mdi-foursquare:before {
  content: "";
}

.mdi-freebsd:before {
  content: "";
}

.mdi-fridge:before {
  content: "";
}

.mdi-fridge-filled:before {
  content: "";
}

.mdi-fridge-filled-bottom:before {
  content: "";
}

.mdi-fridge-filled-top:before {
  content: "";
}

.mdi-fuel:before {
  content: "";
}

.mdi-fullscreen:before {
  content: "";
}

.mdi-fullscreen-exit:before {
  content: "";
}

.mdi-function:before {
  content: "";
}

.mdi-function-variant:before {
  content: "";
}

.mdi-gamepad:before {
  content: "";
}

.mdi-gamepad-variant:before {
  content: "";
}

.mdi-garage:before {
  content: "";
}

.mdi-garage-alert:before {
  content: "";
}

.mdi-garage-open:before {
  content: "";
}

.mdi-gas-cylinder:before {
  content: "";
}

.mdi-gas-station:before {
  content: "";
}

.mdi-gate:before {
  content: "";
}

.mdi-gate-and:before {
  content: "";
}

.mdi-gate-nand:before {
  content: "";
}

.mdi-gate-nor:before {
  content: "";
}

.mdi-gate-not:before {
  content: "";
}

.mdi-gate-or:before {
  content: "";
}

.mdi-gate-xnor:before {
  content: "";
}

.mdi-gate-xor:before {
  content: "";
}

.mdi-gauge:before {
  content: "";
}

.mdi-gauge-empty:before {
  content: "";
}

.mdi-gauge-full:before {
  content: "";
}

.mdi-gauge-low:before {
  content: "";
}

.mdi-gavel:before {
  content: "";
}

.mdi-gender-female:before {
  content: "";
}

.mdi-gender-male:before {
  content: "";
}

.mdi-gender-male-female:before {
  content: "";
}

.mdi-gender-transgender:before {
  content: "";
}

.mdi-gentoo:before {
  content: "";
}

.mdi-gesture:before {
  content: "";
}

.mdi-gesture-double-tap:before {
  content: "";
}

.mdi-gesture-pinch:before {
  content: "謁";
}

.mdi-gesture-spread:before {
  content: "諾";
}

.mdi-gesture-swipe-down:before {
  content: "";
}

.mdi-gesture-swipe-horizontal:before {
  content: "諭";
}

.mdi-gesture-swipe-left:before {
  content: "";
}

.mdi-gesture-swipe-right:before {
  content: "";
}

.mdi-gesture-swipe-up:before {
  content: "";
}

.mdi-gesture-swipe-vertical:before {
  content: "謹";
}

.mdi-gesture-tap:before {
  content: "";
}

.mdi-gesture-two-double-tap:before {
  content: "";
}

.mdi-gesture-two-tap:before {
  content: "";
}

.mdi-ghost:before {
  content: "";
}

.mdi-ghost-off:before {
  content: "林";
}

.mdi-gift:before {
  content: "";
}

.mdi-git:before {
  content: "";
}

.mdi-github-box:before {
  content: "";
}

.mdi-github-circle:before {
  content: "";
}

.mdi-github-face:before {
  content: "";
}

.mdi-glass-cocktail:before {
  content: "";
}

.mdi-glass-flute:before {
  content: "";
}

.mdi-glass-mug:before {
  content: "";
}

.mdi-glass-stange:before {
  content: "";
}

.mdi-glass-tulip:before {
  content: "";
}

.mdi-glass-wine:before {
  content: "";
}

.mdi-glassdoor:before {
  content: "";
}

.mdi-glasses:before {
  content: "";
}

.mdi-globe-model:before {
  content: "";
}

.mdi-gmail:before {
  content: "";
}

.mdi-gnome:before {
  content: "";
}

.mdi-golf:before {
  content: "";
}

.mdi-gondola:before {
  content: "";
}

.mdi-google:before {
  content: "";
}

.mdi-google-allo:before {
  content: "";
}

.mdi-google-analytics:before {
  content: "";
}

.mdi-google-assistant:before {
  content: "";
}

.mdi-google-cardboard:before {
  content: "";
}

.mdi-google-chrome:before {
  content: "";
}

.mdi-google-circles:before {
  content: "";
}

.mdi-google-circles-communities:before {
  content: "";
}

.mdi-google-circles-extended:before {
  content: "";
}

.mdi-google-circles-group:before {
  content: "";
}

.mdi-google-controller:before {
  content: "";
}

.mdi-google-controller-off:before {
  content: "";
}

.mdi-google-drive:before {
  content: "";
}

.mdi-google-earth:before {
  content: "";
}

.mdi-google-fit:before {
  content: "參";
}

.mdi-google-glass:before {
  content: "";
}

.mdi-google-hangouts:before {
  content: "";
}

.mdi-google-home:before {
  content: "";
}

.mdi-google-keep:before {
  content: "";
}

.mdi-google-lens:before {
  content: "淋";
}

.mdi-google-maps:before {
  content: "";
}

.mdi-google-nearby:before {
  content: "";
}

.mdi-google-pages:before {
  content: "";
}

.mdi-google-photos:before {
  content: "";
}

.mdi-google-physical-web:before {
  content: "";
}

.mdi-google-play:before {
  content: "";
}

.mdi-google-plus:before {
  content: "";
}

.mdi-google-plus-box:before {
  content: "";
}

.mdi-google-spreadsheet:before {
  content: "臨";
}

.mdi-google-translate:before {
  content: "";
}

.mdi-google-wallet:before {
  content: "";
}

.mdi-gpu:before {
  content: "";
}

.mdi-gradient:before {
  content: "";
}

.mdi-graphql:before {
  content: "";
}

.mdi-grease-pencil:before {
  content: "";
}

.mdi-greater-than:before {
  content: "塞";
}

.mdi-greater-than-or-equal:before {
  content: "省";
}

.mdi-grid:before {
  content: "";
}

.mdi-grid-large:before {
  content: "";
}

.mdi-grid-off:before {
  content: "";
}

.mdi-group:before {
  content: "";
}

.mdi-guitar-acoustic:before {
  content: "";
}

.mdi-guitar-electric:before {
  content: "";
}

.mdi-guitar-pick:before {
  content: "";
}

.mdi-guitar-pick-outline:before {
  content: "";
}

.mdi-guy-fawkes-mask:before {
  content: "";
}

.mdi-hackernews:before {
  content: "";
}

.mdi-hail:before {
  content: "變";
}

.mdi-hamburger:before {
  content: "";
}

.mdi-hammer:before {
  content: "";
}

.mdi-hand:before {
  content: "祈";
}

.mdi-hand-okay:before {
  content: "祐";
}

.mdi-hand-peace:before {
  content: "祖";
}

.mdi-hand-peace-variant:before {
  content: "祝";
}

.mdi-hand-pointing-down:before {
  content: "禍";
}

.mdi-hand-pointing-left:before {
  content: "禎";
}

.mdi-hand-pointing-right:before {
  content: "";
}

.mdi-hand-pointing-up:before {
  content: "穀";
}

.mdi-hanger:before {
  content: "";
}

.mdi-hard-hat:before {
  content: "葉";
}

.mdi-harddisk:before {
  content: "";
}

.mdi-headphones:before {
  content: "";
}

.mdi-headphones-bluetooth:before {
  content: "說";
}

.mdi-headphones-box:before {
  content: "";
}

.mdi-headphones-off:before {
  content: "";
}

.mdi-headphones-settings:before {
  content: "";
}

.mdi-headset:before {
  content: "";
}

.mdi-headset-dock:before {
  content: "";
}

.mdi-headset-off:before {
  content: "";
}

.mdi-heart:before {
  content: "";
}

.mdi-heart-box:before {
  content: "";
}

.mdi-heart-box-outline:before {
  content: "";
}

.mdi-heart-broken:before {
  content: "";
}

.mdi-heart-circle:before {
  content: "殺";
}

.mdi-heart-circle-outline:before {
  content: "辰";
}

.mdi-heart-half:before {
  content: "";
}

.mdi-heart-half-full:before {
  content: "";
}

.mdi-heart-half-outline:before {
  content: "";
}

.mdi-heart-multiple:before {
  content: "突";
}

.mdi-heart-multiple-outline:before {
  content: "節";
}

.mdi-heart-off:before {
  content: "";
}

.mdi-heart-outline:before {
  content: "";
}

.mdi-heart-pulse:before {
  content: "";
}

.mdi-helicopter:before {
  content: "贈";
}

.mdi-help:before {
  content: "";
}

.mdi-help-box:before {
  content: "";
}

.mdi-help-circle:before {
  content: "";
}

.mdi-help-circle-outline:before {
  content: "";
}

.mdi-help-network:before {
  content: "";
}

.mdi-hexagon:before {
  content: "";
}

.mdi-hexagon-multiple:before {
  content: "";
}

.mdi-hexagon-outline:before {
  content: "";
}

.mdi-hexagon-slice-1:before {
  content: "輸";
}

.mdi-hexagon-slice-2:before {
  content: "遲";
}

.mdi-hexagon-slice-3:before {
  content: "醙";
}

.mdi-hexagon-slice-4:before {
  content: "鉶";
}

.mdi-hexagon-slice-5:before {
  content: "陼";
}

.mdi-hexagon-slice-6:before {
  content: "難";
}

.mdi-hexagram:before {
  content: "靖";
}

.mdi-hexagram-outline:before {
  content: "韛";
}

.mdi-high-definition:before {
  content: "";
}

.mdi-high-definition-box:before {
  content: "";
}

.mdi-highway:before {
  content: "";
}

.mdi-hinduism:before {
  content: "沈";
}

.mdi-history:before {
  content: "";
}

.mdi-hockey-puck:before {
  content: "";
}

.mdi-hockey-sticks:before {
  content: "";
}

.mdi-hololens:before {
  content: "";
}

.mdi-home:before {
  content: "";
}

.mdi-home-account:before {
  content: "";
}

.mdi-home-alert:before {
  content: "";
}

.mdi-home-assistant:before {
  content: "";
}

.mdi-home-automation:before {
  content: "";
}

.mdi-home-circle:before {
  content: "";
}

.mdi-home-currency-usd:before {
  content: "";
}

.mdi-home-heart:before {
  content: "";
}

.mdi-home-lock:before {
  content: "";
}

.mdi-home-lock-open:before {
  content: "";
}

.mdi-home-map-marker:before {
  content: "";
}

.mdi-home-minus:before {
  content: "拾";
}

.mdi-home-modern:before {
  content: "";
}

.mdi-home-outline:before {
  content: "";
}

.mdi-home-plus:before {
  content: "若";
}

.mdi-home-variant:before {
  content: "";
}

.mdi-hook:before {
  content: "";
}

.mdi-hook-off:before {
  content: "";
}

.mdi-hops:before {
  content: "";
}

.mdi-horseshoe:before {
  content: "練";
}

.mdi-hospital:before {
  content: "";
}

.mdi-hospital-building:before {
  content: "";
}

.mdi-hospital-marker:before {
  content: "";
}

.mdi-hot-tub:before {
  content: "";
}

.mdi-hotel:before {
  content: "";
}

.mdi-houzz:before {
  content: "";
}

.mdi-houzz-box:before {
  content: "";
}

.mdi-hulu:before {
  content: "";
}

.mdi-human:before {
  content: "";
}

.mdi-human-child:before {
  content: "";
}

.mdi-human-female:before {
  content: "";
}

.mdi-human-female-boy:before {
  content: "縉";
}

.mdi-human-female-female:before {
  content: "繁";
}

.mdi-human-female-girl:before {
  content: "署";
}

.mdi-human-greeting:before {
  content: "";
}

.mdi-human-handsdown:before {
  content: "";
}

.mdi-human-handsup:before {
  content: "";
}

.mdi-human-male:before {
  content: "";
}

.mdi-human-male-boy:before {
  content: "者";
}

.mdi-human-male-female:before {
  content: "";
}

.mdi-human-male-girl:before {
  content: "臭";
}

.mdi-human-male-male:before {
  content: "艹";
}

.mdi-human-pregnant:before {
  content: "";
}

.mdi-humble-bundle:before {
  content: "";
}

.mdi-ice-cream:before {
  content: "";
}

.mdi-image:before {
  content: "";
}

.mdi-image-album:before {
  content: "";
}

.mdi-image-area:before {
  content: "";
}

.mdi-image-area-close:before {
  content: "";
}

.mdi-image-broken:before {
  content: "";
}

.mdi-image-broken-variant:before {
  content: "";
}

.mdi-image-filter:before {
  content: "";
}

.mdi-image-filter-black-white:before {
  content: "";
}

.mdi-image-filter-center-focus:before {
  content: "";
}

.mdi-image-filter-center-focus-weak:before {
  content: "";
}

.mdi-image-filter-drama:before {
  content: "";
}

.mdi-image-filter-frames:before {
  content: "";
}

.mdi-image-filter-hdr:before {
  content: "";
}

.mdi-image-filter-none:before {
  content: "";
}

.mdi-image-filter-tilt-shift:before {
  content: "";
}

.mdi-image-filter-vintage:before {
  content: "";
}

.mdi-image-move:before {
  content: "立";
}

.mdi-image-multiple:before {
  content: "";
}

.mdi-image-off:before {
  content: "";
}

.mdi-image-outline:before {
  content: "掠";
}

.mdi-image-plus:before {
  content: "";
}

.mdi-image-search:before {
  content: "略";
}

.mdi-image-search-outline:before {
  content: "亮";
}

.mdi-import:before {
  content: "";
}

.mdi-inbox:before {
  content: "";
}

.mdi-inbox-arrow-down:before {
  content: "";
}

.mdi-inbox-arrow-up:before {
  content: "";
}

.mdi-inbox-multiple:before {
  content: "";
}

.mdi-incognito:before {
  content: "";
}

.mdi-infinity:before {
  content: "";
}

.mdi-information:before {
  content: "";
}

.mdi-information-outline:before {
  content: "";
}

.mdi-information-variant:before {
  content: "";
}

.mdi-instagram:before {
  content: "";
}

.mdi-instapaper:before {
  content: "";
}

.mdi-internet-explorer:before {
  content: "";
}

.mdi-invert-colors:before {
  content: "";
}

.mdi-ip:before {
  content: "艹";
}

.mdi-ip-network:before {
  content: "著";
}

.mdi-islam:before {
  content: "兩";
}

.mdi-itunes:before {
  content: "";
}

.mdi-jeepney:before {
  content: "";
}

.mdi-jira:before {
  content: "";
}

.mdi-jquery:before {
  content: "";
}

.mdi-jsfiddle:before {
  content: "";
}

.mdi-json:before {
  content: "";
}

.mdi-judaism:before {
  content: "凉";
}

.mdi-karate:before {
  content: "";
}

.mdi-keg:before {
  content: "";
}

.mdi-kettle:before {
  content: "";
}

.mdi-key:before {
  content: "";
}

.mdi-key-change:before {
  content: "";
}

.mdi-key-minus:before {
  content: "";
}

.mdi-key-plus:before {
  content: "";
}

.mdi-key-remove:before {
  content: "";
}

.mdi-key-variant:before {
  content: "";
}

.mdi-keyboard:before {
  content: "";
}

.mdi-keyboard-backspace:before {
  content: "";
}

.mdi-keyboard-caps:before {
  content: "";
}

.mdi-keyboard-close:before {
  content: "";
}

.mdi-keyboard-off:before {
  content: "";
}

.mdi-keyboard-outline:before {
  content: "梁";
}

.mdi-keyboard-return:before {
  content: "";
}

.mdi-keyboard-settings:before {
  content: "笠";
}

.mdi-keyboard-settings-outline:before {
  content: "粒";
}

.mdi-keyboard-tab:before {
  content: "";
}

.mdi-keyboard-variant:before {
  content: "";
}

.mdi-kickstarter:before {
  content: "";
}

.mdi-knife:before {
  content: "狀";
}

.mdi-knife-military:before {
  content: "炙";
}

.mdi-kodi:before {
  content: "";
}

.mdi-label:before {
  content: "";
}

.mdi-label-off:before {
  content: "響";
}

.mdi-label-off-outline:before {
  content: "頋";
}

.mdi-label-outline:before {
  content: "";
}

.mdi-label-variant:before {
  content: "頻";
}

.mdi-label-variant-outline:before {
  content: "鬒";
}

.mdi-ladybug:before {
  content: "";
}

.mdi-lambda:before {
  content: "";
}

.mdi-lamp:before {
  content: "";
}

.mdi-lan:before {
  content: "";
}

.mdi-lan-connect:before {
  content: "";
}

.mdi-lan-disconnect:before {
  content: "";
}

.mdi-lan-pending:before {
  content: "";
}

.mdi-language-c:before {
  content: "";
}

.mdi-language-cpp:before {
  content: "";
}

.mdi-language-csharp:before {
  content: "";
}

.mdi-language-css3:before {
  content: "";
}

.mdi-language-go:before {
  content: "";
}

.mdi-language-html5:before {
  content: "";
}

.mdi-language-java:before {
  content: "﬜";
}

.mdi-language-javascript:before {
  content: "";
}

.mdi-language-lua:before {
  content: "";
}

.mdi-language-php:before {
  content: "";
}

.mdi-language-python:before {
  content: "";
}

.mdi-language-python-text:before {
  content: "";
}

.mdi-language-r:before {
  content: "";
}

.mdi-language-ruby-on-rails:before {
  content: "龜";
}

.mdi-language-swift:before {
  content: "";
}

.mdi-language-typescript:before {
  content: "";
}

.mdi-laptop:before {
  content: "";
}

.mdi-laptop-chromebook:before {
  content: "";
}

.mdi-laptop-mac:before {
  content: "";
}

.mdi-laptop-off:before {
  content: "";
}

.mdi-laptop-windows:before {
  content: "";
}

.mdi-laravel:before {
  content: "𢡊";
}

.mdi-lastfm:before {
  content: "";
}

.mdi-lastpass:before {
  content: "";
}

.mdi-launch:before {
  content: "";
}

.mdi-lava-lamp:before {
  content: "";
}

.mdi-layers:before {
  content: "";
}

.mdi-layers-off:before {
  content: "";
}

.mdi-layers-off-outline:before {
  content: "識";
}

.mdi-layers-outline:before {
  content: "什";
}

.mdi-lead-pencil:before {
  content: "";
}

.mdi-leaf:before {
  content: "";
}

.mdi-led-off:before {
  content: "";
}

.mdi-led-on:before {
  content: "";
}

.mdi-led-outline:before {
  content: "";
}

.mdi-led-strip:before {
  content: "";
}

.mdi-led-variant-off:before {
  content: "";
}

.mdi-led-variant-on:before {
  content: "";
}

.mdi-led-variant-outline:before {
  content: "";
}

.mdi-less-than:before {
  content: "糧";
}

.mdi-less-than-or-equal:before {
  content: "良";
}

.mdi-library:before {
  content: "";
}

.mdi-library-books:before {
  content: "";
}

.mdi-library-music:before {
  content: "";
}

.mdi-library-plus:before {
  content: "";
}

.mdi-lifebuoy:before {
  content: "";
}

.mdi-light-switch:before {
  content: "諒";
}

.mdi-lightbulb:before {
  content: "";
}

.mdi-lightbulb-on:before {
  content: "";
}

.mdi-lightbulb-on-outline:before {
  content: "";
}

.mdi-lightbulb-outline:before {
  content: "";
}

.mdi-lighthouse:before {
  content: "茶";
}

.mdi-lighthouse-on:before {
  content: "刺";
}

.mdi-link:before {
  content: "";
}

.mdi-link-off:before {
  content: "";
}

.mdi-link-variant:before {
  content: "";
}

.mdi-link-variant-off:before {
  content: "";
}

.mdi-linkedin:before {
  content: "";
}

.mdi-linkedin-box:before {
  content: "";
}

.mdi-linux:before {
  content: "";
}

.mdi-linux-mint:before {
  content: "";
}

.mdi-litecoin:before {
  content: "褐";
}

.mdi-loading:before {
  content: "";
}

.mdi-lock:before {
  content: "";
}

.mdi-lock-alert:before {
  content: "";
}

.mdi-lock-clock:before {
  content: "量";
}

.mdi-lock-open:before {
  content: "";
}

.mdi-lock-open-outline:before {
  content: "";
}

.mdi-lock-outline:before {
  content: "";
}

.mdi-lock-pattern:before {
  content: "";
}

.mdi-lock-plus:before {
  content: "";
}

.mdi-lock-question:before {
  content: "";
}

.mdi-lock-reset:before {
  content: "";
}

.mdi-lock-smart:before {
  content: "";
}

.mdi-locker:before {
  content: "";
}

.mdi-locker-multiple:before {
  content: "";
}

.mdi-login:before {
  content: "";
}

.mdi-login-variant:before {
  content: "";
}

.mdi-logout:before {
  content: "";
}

.mdi-logout-variant:before {
  content: "";
}

.mdi-looks:before {
  content: "";
}

.mdi-loop:before {
  content: "";
}

.mdi-loupe:before {
  content: "";
}

.mdi-lumx:before {
  content: "";
}

.mdi-lyft:before {
  content: "יִ";
}

.mdi-magnet:before {
  content: "";
}

.mdi-magnet-on:before {
  content: "";
}

.mdi-magnify:before {
  content: "";
}

.mdi-magnify-close:before {
  content: "勵";
}

.mdi-magnify-minus:before {
  content: "";
}

.mdi-magnify-minus-cursor:before {
  content: "視";
}

.mdi-magnify-minus-outline:before {
  content: "";
}

.mdi-magnify-plus:before {
  content: "";
}

.mdi-magnify-plus-cursor:before {
  content: "謁";
}

.mdi-magnify-plus-outline:before {
  content: "";
}

.mdi-mail-ru:before {
  content: "";
}

.mdi-mailbox:before {
  content: "";
}

.mdi-map:before {
  content: "";
}

.mdi-map-legend:before {
  content: "切";
}

.mdi-map-marker:before {
  content: "";
}

.mdi-map-marker-circle:before {
  content: "";
}

.mdi-map-marker-distance:before {
  content: "";
}

.mdi-map-marker-minus:before {
  content: "";
}

.mdi-map-marker-multiple:before {
  content: "";
}

.mdi-map-marker-off:before {
  content: "";
}

.mdi-map-marker-outline:before {
  content: "";
}

.mdi-map-marker-plus:before {
  content: "";
}

.mdi-map-marker-radius:before {
  content: "";
}

.mdi-map-minus:before {
  content: "呂";
}

.mdi-map-outline:before {
  content: "女";
}

.mdi-map-plus:before {
  content: "廬";
}

.mdi-map-search:before {
  content: "旅";
}

.mdi-map-search-outline:before {
  content: "濾";
}

.mdi-margin:before {
  content: "";
}

.mdi-markdown:before {
  content: "";
}

.mdi-marker:before {
  content: "";
}

.mdi-marker-check:before {
  content: "";
}

.mdi-mastodon:before {
  content: "𢡄";
}

.mdi-mastodon-variant:before {
  content: "𣏕";
}

.mdi-material-design:before {
  content: "礪";
}

.mdi-material-ui:before {
  content: "";
}

.mdi-math-compass:before {
  content: "";
}

.mdi-matrix:before {
  content: "";
}

.mdi-maxcdn:before {
  content: "";
}

.mdi-medal:before {
  content: "閭";
}

.mdi-medical-bag:before {
  content: "";
}

.mdi-medium:before {
  content: "";
}

.mdi-meetup:before {
  content: "㮝";
}

.mdi-memory:before {
  content: "";
}

.mdi-menu:before {
  content: "";
}

.mdi-menu-down:before {
  content: "";
}

.mdi-menu-down-outline:before {
  content: "";
}

.mdi-menu-left:before {
  content: "";
}

.mdi-menu-left-outline:before {
  content: "度";
}

.mdi-menu-right:before {
  content: "";
}

.mdi-menu-right-outline:before {
  content: "拓";
}

.mdi-menu-swap:before {
  content: "謹";
}

.mdi-menu-swap-outline:before {
  content: "賓";
}

.mdi-menu-up:before {
  content: "";
}

.mdi-menu-up-outline:before {
  content: "";
}

.mdi-message:before {
  content: "";
}

.mdi-message-alert:before {
  content: "";
}

.mdi-message-alert-outline:before {
  content: "糖";
}

.mdi-message-bulleted:before {
  content: "";
}

.mdi-message-bulleted-off:before {
  content: "";
}

.mdi-message-draw:before {
  content: "";
}

.mdi-message-image:before {
  content: "";
}

.mdi-message-outline:before {
  content: "";
}

.mdi-message-plus:before {
  content: "";
}

.mdi-message-processing:before {
  content: "";
}

.mdi-message-reply:before {
  content: "";
}

.mdi-message-reply-text:before {
  content: "";
}

.mdi-message-settings:before {
  content: "";
}

.mdi-message-settings-variant:before {
  content: "";
}

.mdi-message-text:before {
  content: "";
}

.mdi-message-text-outline:before {
  content: "";
}

.mdi-message-video:before {
  content: "";
}

.mdi-meteor:before {
  content: "";
}

.mdi-metronome:before {
  content: "";
}

.mdi-metronome-tick:before {
  content: "";
}

.mdi-micro-sd:before {
  content: "";
}

.mdi-microphone:before {
  content: "";
}

.mdi-microphone-minus:before {
  content: "";
}

.mdi-microphone-off:before {
  content: "";
}

.mdi-microphone-outline:before {
  content: "";
}

.mdi-microphone-plus:before {
  content: "";
}

.mdi-microphone-settings:before {
  content: "";
}

.mdi-microphone-variant:before {
  content: "";
}

.mdi-microphone-variant-off:before {
  content: "";
}

.mdi-microscope:before {
  content: "";
}

.mdi-microsoft:before {
  content: "";
}

.mdi-microsoft-dynamics:before {
  content: "驪";
}

.mdi-midi:before {
  content: "";
}

.mdi-midi-port:before {
  content: "";
}

.mdi-minecraft:before {
  content: "";
}

.mdi-mini-sd:before {
  content: "宅";
}

.mdi-minidisc:before {
  content: "洞";
}

.mdi-minus:before {
  content: "";
}

.mdi-minus-box:before {
  content: "";
}

.mdi-minus-box-outline:before {
  content: "";
}

.mdi-minus-circle:before {
  content: "";
}

.mdi-minus-circle-outline:before {
  content: "";
}

.mdi-minus-network:before {
  content: "";
}

.mdi-mixcloud:before {
  content: "";
}

.mdi-mixed-reality:before {
  content: "";
}

.mdi-mixer:before {
  content: "";
}

.mdi-monitor:before {
  content: "";
}

.mdi-monitor-cellphone:before {
  content: "麗";
}

.mdi-monitor-cellphone-star:before {
  content: "黎";
}

.mdi-monitor-dashboard:before {
  content: "暴";
}

.mdi-monitor-multiple:before {
  content: "";
}

.mdi-more:before {
  content: "";
}

.mdi-motorbike:before {
  content: "";
}

.mdi-mouse:before {
  content: "";
}

.mdi-mouse-bluetooth:before {
  content: "力";
}

.mdi-mouse-off:before {
  content: "";
}

.mdi-mouse-variant:before {
  content: "";
}

.mdi-mouse-variant-off:before {
  content: "";
}

.mdi-move-resize:before {
  content: "";
}

.mdi-move-resize-variant:before {
  content: "";
}

.mdi-movie:before {
  content: "";
}

.mdi-movie-roll:before {
  content: "";
}

.mdi-muffin:before {
  content: "曆";
}

.mdi-multiplication:before {
  content: "";
}

.mdi-multiplication-box:before {
  content: "";
}

.mdi-mushroom:before {
  content: "";
}

.mdi-mushroom-outline:before {
  content: "";
}

.mdi-music:before {
  content: "";
}

.mdi-music-box:before {
  content: "";
}

.mdi-music-box-outline:before {
  content: "";
}

.mdi-music-circle:before {
  content: "";
}

.mdi-music-circle-outline:before {
  content: "䀘";
}

.mdi-music-note:before {
  content: "";
}

.mdi-music-note-bluetooth:before {
  content: "";
}

.mdi-music-note-bluetooth-off:before {
  content: "";
}

.mdi-music-note-eighth:before {
  content: "";
}

.mdi-music-note-half:before {
  content: "";
}

.mdi-music-note-off:before {
  content: "";
}

.mdi-music-note-quarter:before {
  content: "";
}

.mdi-music-note-sixteenth:before {
  content: "";
}

.mdi-music-note-whole:before {
  content: "";
}

.mdi-music-off:before {
  content: "";
}

.mdi-nas:before {
  content: "";
}

.mdi-nativescript:before {
  content: "";
}

.mdi-nature:before {
  content: "";
}

.mdi-nature-people:before {
  content: "";
}

.mdi-navigation:before {
  content: "";
}

.mdi-near-me:before {
  content: "";
}

.mdi-needle:before {
  content: "";
}

.mdi-netflix:before {
  content: "";
}

.mdi-network:before {
  content: "";
}

.mdi-network-strength-1:before {
  content: "";
}

.mdi-network-strength-1-alert:before {
  content: "";
}

.mdi-network-strength-2:before {
  content: "";
}

.mdi-network-strength-2-alert:before {
  content: "";
}

.mdi-network-strength-3:before {
  content: "";
}

.mdi-network-strength-3-alert:before {
  content: "";
}

.mdi-network-strength-4:before {
  content: "";
}

.mdi-network-strength-4-alert:before {
  content: "";
}

.mdi-network-strength-off:before {
  content: "";
}

.mdi-network-strength-off-outline:before {
  content: "";
}

.mdi-network-strength-outline:before {
  content: "";
}

.mdi-new-box:before {
  content: "";
}

.mdi-newspaper:before {
  content: "";
}

.mdi-nfc:before {
  content: "";
}

.mdi-nfc-tap:before {
  content: "";
}

.mdi-nfc-variant:before {
  content: "";
}

.mdi-ninja:before {
  content: "";
}

.mdi-nintendo-switch:before {
  content: "";
}

.mdi-nodejs:before {
  content: "";
}

.mdi-not-equal:before {
  content: "歷";
}

.mdi-not-equal-variant:before {
  content: "轢";
}

.mdi-note:before {
  content: "";
}

.mdi-note-multiple:before {
  content: "";
}

.mdi-note-multiple-outline:before {
  content: "";
}

.mdi-note-outline:before {
  content: "";
}

.mdi-note-plus:before {
  content: "";
}

.mdi-note-plus-outline:before {
  content: "";
}

.mdi-note-text:before {
  content: "";
}

.mdi-notebook:before {
  content: "";
}

.mdi-notification-clear-all:before {
  content: "";
}

.mdi-npm:before {
  content: "";
}

.mdi-npm-variant:before {
  content: "年";
}

.mdi-npm-variant-outline:before {
  content: "憐";
}

.mdi-nuke:before {
  content: "";
}

.mdi-null:before {
  content: "";
}

.mdi-numeric:before {
  content: "";
}

.mdi-numeric-0:before {
  content: "0";
}

.mdi-numeric-0-box:before {
  content: "";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-0-box-outline:before {
  content: "";
}

.mdi-numeric-1:before {
  content: "1";
}

.mdi-numeric-1-box:before {
  content: "";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-1-box-outline:before {
  content: "";
}

.mdi-numeric-2:before {
  content: "2";
}

.mdi-numeric-2-box:before {
  content: "";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-2-box-outline:before {
  content: "";
}

.mdi-numeric-3:before {
  content: "3";
}

.mdi-numeric-3-box:before {
  content: "";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-3-box-outline:before {
  content: "";
}

.mdi-numeric-4:before {
  content: "4";
}

.mdi-numeric-4-box:before {
  content: "";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-4-box-outline:before {
  content: "";
}

.mdi-numeric-5:before {
  content: "5";
}

.mdi-numeric-5-box:before {
  content: "";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-5-box-outline:before {
  content: "";
}

.mdi-numeric-6:before {
  content: "6";
}

.mdi-numeric-6-box:before {
  content: "";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-6-box-outline:before {
  content: "";
}

.mdi-numeric-7:before {
  content: "7";
}

.mdi-numeric-7-box:before {
  content: "";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-7-box-outline:before {
  content: "";
}

.mdi-numeric-8:before {
  content: "8";
}

.mdi-numeric-8-box:before {
  content: "";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-8-box-outline:before {
  content: "";
}

.mdi-numeric-9:before {
  content: "9";
}

.mdi-numeric-9-box:before {
  content: "";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-9-box-outline:before {
  content: "";
}

.mdi-numeric-9-plus-box:before {
  content: "";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "";
}

.mdi-nut:before {
  content: "";
}

.mdi-nutrition:before {
  content: "";
}

.mdi-oar:before {
  content: "";
}

.mdi-octagon:before {
  content: "";
}

.mdi-octagon-outline:before {
  content: "";
}

.mdi-octagram:before {
  content: "";
}

.mdi-octagram-outline:before {
  content: "";
}

.mdi-odnoklassniki:before {
  content: "";
}

.mdi-office:before {
  content: "";
}

.mdi-office-building:before {
  content: "戀";
}

.mdi-oil:before {
  content: "";
}

.mdi-oil-temperature:before {
  content: "";
}

.mdi-omega:before {
  content: "";
}

.mdi-onedrive:before {
  content: "";
}

.mdi-onenote:before {
  content: "";
}

.mdi-onepassword:before {
  content: "";
}

.mdi-opacity:before {
  content: "";
}

.mdi-open-in-app:before {
  content: "";
}

.mdi-open-in-new:before {
  content: "";
}

.mdi-openid:before {
  content: "";
}

.mdi-opera:before {
  content: "";
}

.mdi-orbit:before {
  content: "";
}

.mdi-origin:before {
  content: "שׂ";
}

.mdi-ornament:before {
  content: "";
}

.mdi-ornament-variant:before {
  content: "";
}

.mdi-owl:before {
  content: "";
}

.mdi-package:before {
  content: "";
}

.mdi-package-down:before {
  content: "";
}

.mdi-package-up:before {
  content: "";
}

.mdi-package-variant:before {
  content: "";
}

.mdi-package-variant-closed:before {
  content: "";
}

.mdi-page-first:before {
  content: "";
}

.mdi-page-last:before {
  content: "";
}

.mdi-page-layout-body:before {
  content: "";
}

.mdi-page-layout-footer:before {
  content: "";
}

.mdi-page-layout-header:before {
  content: "";
}

.mdi-page-layout-sidebar-left:before {
  content: "";
}

.mdi-page-layout-sidebar-right:before {
  content: "";
}

.mdi-palette:before {
  content: "";
}

.mdi-palette-advanced:before {
  content: "";
}

.mdi-palette-swatch:before {
  content: "";
}

.mdi-panda:before {
  content: "";
}

.mdi-pandora:before {
  content: "";
}

.mdi-panorama:before {
  content: "";
}

.mdi-panorama-fisheye:before {
  content: "";
}

.mdi-panorama-horizontal:before {
  content: "";
}

.mdi-panorama-vertical:before {
  content: "";
}

.mdi-panorama-wide-angle:before {
  content: "";
}

.mdi-paper-cut-vertical:before {
  content: "";
}

.mdi-paperclip:before {
  content: "";
}

.mdi-parking:before {
  content: "";
}

.mdi-passport:before {
  content: "";
}

.mdi-patreon:before {
  content: "";
}

.mdi-pause:before {
  content: "";
}

.mdi-pause-circle:before {
  content: "";
}

.mdi-pause-circle-outline:before {
  content: "";
}

.mdi-pause-octagon:before {
  content: "";
}

.mdi-pause-octagon-outline:before {
  content: "";
}

.mdi-paw:before {
  content: "";
}

.mdi-paw-off:before {
  content: "";
}

.mdi-paypal:before {
  content: "";
}

.mdi-peace:before {
  content: "";
}

.mdi-pen:before {
  content: "";
}

.mdi-pencil:before {
  content: "";
}

.mdi-pencil-box:before {
  content: "";
}

.mdi-pencil-box-outline:before {
  content: "";
}

.mdi-pencil-circle:before {
  content: "";
}

.mdi-pencil-circle-outline:before {
  content: "";
}

.mdi-pencil-lock:before {
  content: "";
}

.mdi-pencil-off:before {
  content: "";
}

.mdi-pentagon:before {
  content: "";
}

.mdi-pentagon-outline:before {
  content: "";
}

.mdi-percent:before {
  content: "";
}

.mdi-periodic-table:before {
  content: "";
}

.mdi-periodic-table-co2:before {
  content: "";
}

.mdi-periscope:before {
  content: "";
}

.mdi-pharmacy:before {
  content: "";
}

.mdi-phone:before {
  content: "";
}

.mdi-phone-bluetooth:before {
  content: "";
}

.mdi-phone-classic:before {
  content: "";
}

.mdi-phone-forward:before {
  content: "";
}

.mdi-phone-hangup:before {
  content: "";
}

.mdi-phone-in-talk:before {
  content: "";
}

.mdi-phone-incoming:before {
  content: "";
}

.mdi-phone-lock:before {
  content: "";
}

.mdi-phone-log:before {
  content: "";
}

.mdi-phone-minus:before {
  content: "";
}

.mdi-phone-missed:before {
  content: "";
}

.mdi-phone-outgoing:before {
  content: "";
}

.mdi-phone-paused:before {
  content: "";
}

.mdi-phone-plus:before {
  content: "";
}

.mdi-phone-return:before {
  content: "";
}

.mdi-phone-rotate-landscape:before {
  content: "";
}

.mdi-phone-rotate-portrait:before {
  content: "";
}

.mdi-phone-settings:before {
  content: "";
}

.mdi-phone-voip:before {
  content: "";
}

.mdi-pi:before {
  content: "";
}

.mdi-pi-box:before {
  content: "";
}

.mdi-piano:before {
  content: "";
}

.mdi-pickaxe:before {
  content: "";
}

.mdi-pier:before {
  content: "";
}

.mdi-pier-crane:before {
  content: "";
}

.mdi-pig:before {
  content: "";
}

.mdi-pill:before {
  content: "";
}

.mdi-pillar:before {
  content: "";
}

.mdi-pin:before {
  content: "";
}

.mdi-pin-off:before {
  content: "";
}

.mdi-pin-off-outline:before {
  content: "勞";
}

.mdi-pin-outline:before {
  content: "擄";
}

.mdi-pine-tree:before {
  content: "";
}

.mdi-pine-tree-box:before {
  content: "";
}

.mdi-pinterest:before {
  content: "";
}

.mdi-pinterest-box:before {
  content: "";
}

.mdi-pinwheel:before {
  content: "䀹";
}

.mdi-pinwheel-outline:before {
  content: "𥉉";
}

.mdi-pipe:before {
  content: "";
}

.mdi-pipe-disconnected:before {
  content: "";
}

.mdi-pipe-leak:before {
  content: "";
}

.mdi-pirate:before {
  content: "輻";
}

.mdi-pistol:before {
  content: "";
}

.mdi-piston:before {
  content: "";
}

.mdi-pizza:before {
  content: "";
}

.mdi-plane-shield:before {
  content: "";
}

.mdi-play:before {
  content: "";
}

.mdi-play-box-outline:before {
  content: "";
}

.mdi-play-circle:before {
  content: "";
}

.mdi-play-circle-outline:before {
  content: "";
}

.mdi-play-network:before {
  content: "";
}

.mdi-play-pause:before {
  content: "";
}

.mdi-play-protected-content:before {
  content: "";
}

.mdi-play-speed:before {
  content: "";
}

.mdi-playlist-check:before {
  content: "";
}

.mdi-playlist-edit:before {
  content: "";
}

.mdi-playlist-minus:before {
  content: "";
}

.mdi-playlist-play:before {
  content: "";
}

.mdi-playlist-plus:before {
  content: "";
}

.mdi-playlist-remove:before {
  content: "";
}

.mdi-playstation:before {
  content: "";
}

.mdi-plex:before {
  content: "";
}

.mdi-plus:before {
  content: "";
}

.mdi-plus-box:before {
  content: "";
}

.mdi-plus-box-outline:before {
  content: "";
}

.mdi-plus-circle:before {
  content: "";
}

.mdi-plus-circle-multiple-outline:before {
  content: "";
}

.mdi-plus-circle-outline:before {
  content: "";
}

.mdi-plus-minus:before {
  content: "撚";
}

.mdi-plus-minus-box:before {
  content: "漣";
}

.mdi-plus-network:before {
  content: "";
}

.mdi-plus-one:before {
  content: "";
}

.mdi-plus-outline:before {
  content: "";
}

.mdi-pocket:before {
  content: "";
}

.mdi-podcast:before {
  content: "煉";
}

.mdi-pokeball:before {
  content: "";
}

.mdi-pokemon-go:before {
  content: "行";
}

.mdi-poker-chip:before {
  content: "";
}

.mdi-polaroid:before {
  content: "";
}

.mdi-poll:before {
  content: "";
}

.mdi-poll-box:before {
  content: "";
}

.mdi-polymer:before {
  content: "";
}

.mdi-pool:before {
  content: "";
}

.mdi-popcorn:before {
  content: "";
}

.mdi-pot:before {
  content: "";
}

.mdi-pot-mix:before {
  content: "";
}

.mdi-pound:before {
  content: "";
}

.mdi-pound-box:before {
  content: "";
}

.mdi-power:before {
  content: "";
}

.mdi-power-cycle:before {
  content: "豈";
}

.mdi-power-off:before {
  content: "更";
}

.mdi-power-on:before {
  content: "車";
}

.mdi-power-plug:before {
  content: "";
}

.mdi-power-plug-off:before {
  content: "";
}

.mdi-power-settings:before {
  content: "";
}

.mdi-power-sleep:before {
  content: "賈";
}

.mdi-power-socket:before {
  content: "";
}

.mdi-power-socket-au:before {
  content: "滑";
}

.mdi-power-socket-eu:before {
  content: "";
}

.mdi-power-socket-uk:before {
  content: "";
}

.mdi-power-socket-us:before {
  content: "";
}

.mdi-power-standby:before {
  content: "串";
}

.mdi-powershell:before {
  content: "降";
}

.mdi-prescription:before {
  content: "";
}

.mdi-presentation:before {
  content: "";
}

.mdi-presentation-play:before {
  content: "";
}

.mdi-printer:before {
  content: "";
}

.mdi-printer-3d:before {
  content: "";
}

.mdi-printer-alert:before {
  content: "";
}

.mdi-printer-settings:before {
  content: "";
}

.mdi-printer-wireless:before {
  content: "見";
}

.mdi-priority-high:before {
  content: "";
}

.mdi-priority-low:before {
  content: "";
}

.mdi-professional-hexagon:before {
  content: "";
}

.mdi-progress-check:before {
  content: "璉";
}

.mdi-progress-clock:before {
  content: "秊";
}

.mdi-progress-download:before {
  content: "練";
}

.mdi-progress-upload:before {
  content: "聯";
}

.mdi-projector:before {
  content: "";
}

.mdi-projector-screen:before {
  content: "";
}

.mdi-publish:before {
  content: "";
}

.mdi-pulse:before {
  content: "";
}

.mdi-puzzle:before {
  content: "";
}

.mdi-puzzle-outline:before {
  content: "贈";
}

.mdi-qi:before {
  content: "輦";
}

.mdi-qqchat:before {
  content: "";
}

.mdi-qrcode:before {
  content: "";
}

.mdi-qrcode-edit:before {
  content: "";
}

.mdi-qrcode-scan:before {
  content: "";
}

.mdi-quadcopter:before {
  content: "";
}

.mdi-quality-high:before {
  content: "";
}

.mdi-quality-low:before {
  content: "廓";
}

.mdi-quality-medium:before {
  content: "兀";
}

.mdi-quicktime:before {
  content: "";
}

.mdi-rabbit:before {
  content: "句";
}

.mdi-radar:before {
  content: "";
}

.mdi-radiator:before {
  content: "";
}

.mdi-radiator-disabled:before {
  content: "𥳐";
}

.mdi-radiator-off:before {
  content: "𧻓";
}

.mdi-radio:before {
  content: "";
}

.mdi-radio-handheld:before {
  content: "";
}

.mdi-radio-tower:before {
  content: "";
}

.mdi-radioactive:before {
  content: "";
}

.mdi-radiobox-blank:before {
  content: "";
}

.mdi-radiobox-marked:before {
  content: "";
}

.mdi-raspberrypi:before {
  content: "";
}

.mdi-ray-end:before {
  content: "";
}

.mdi-ray-end-arrow:before {
  content: "";
}

.mdi-ray-start:before {
  content: "";
}

.mdi-ray-start-arrow:before {
  content: "";
}

.mdi-ray-start-end:before {
  content: "";
}

.mdi-ray-vertex:before {
  content: "";
}

.mdi-react:before {
  content: "";
}

.mdi-read:before {
  content: "";
}

.mdi-receipt:before {
  content: "";
}

.mdi-record:before {
  content: "";
}

.mdi-record-player:before {
  content: "蓮";
}

.mdi-record-rec:before {
  content: "";
}

.mdi-recycle:before {
  content: "";
}

.mdi-reddit:before {
  content: "";
}

.mdi-redo:before {
  content: "";
}

.mdi-redo-variant:before {
  content: "";
}

.mdi-reflect-horizontal:before {
  content: "嗀";
}

.mdi-reflect-vertical:before {
  content: "﨎";
}

.mdi-refresh:before {
  content: "";
}

.mdi-regex:before {
  content: "";
}

.mdi-registered-trademark:before {
  content: "辶";
}

.mdi-relative-scale:before {
  content: "";
}

.mdi-reload:before {
  content: "";
}

.mdi-reminder:before {
  content: "";
}

.mdi-remote:before {
  content: "";
}

.mdi-remote-desktop:before {
  content: "";
}

.mdi-rename-box:before {
  content: "";
}

.mdi-reorder-horizontal:before {
  content: "";
}

.mdi-reorder-vertical:before {
  content: "";
}

.mdi-repeat:before {
  content: "";
}

.mdi-repeat-off:before {
  content: "";
}

.mdi-repeat-once:before {
  content: "";
}

.mdi-replay:before {
  content: "";
}

.mdi-reply:before {
  content: "";
}

.mdi-reply-all:before {
  content: "";
}

.mdi-reproduction:before {
  content: "";
}

.mdi-resistor:before {
  content: "ײַ";
}

.mdi-resistor-nodes:before {
  content: "ﬠ";
}

.mdi-resize:before {
  content: "逸";
}

.mdi-resize-bottom-right:before {
  content: "";
}

.mdi-responsive:before {
  content: "";
}

.mdi-restart:before {
  content: "";
}

.mdi-restore:before {
  content: "連";
}

.mdi-restore-clock:before {
  content: "";
}

.mdi-rewind:before {
  content: "";
}

.mdi-rewind-outline:before {
  content: "";
}

.mdi-rhombus:before {
  content: "";
}

.mdi-rhombus-medium:before {
  content: "﨏";
}

.mdi-rhombus-outline:before {
  content: "";
}

.mdi-rhombus-split:before {
  content: "塚";
}

.mdi-ribbon:before {
  content: "";
}

.mdi-rice:before {
  content: "";
}

.mdi-ring:before {
  content: "";
}

.mdi-road:before {
  content: "";
}

.mdi-road-variant:before {
  content: "";
}

.mdi-robot:before {
  content: "";
}

.mdi-robot-industrial:before {
  content: "ﬡ";
}

.mdi-robot-vacuum:before {
  content: "";
}

.mdi-robot-vacuum-variant:before {
  content: "龜";
}

.mdi-rocket:before {
  content: "";
}

.mdi-room-service:before {
  content: "";
}

.mdi-rotate-3d:before {
  content: "";
}

.mdi-rotate-left:before {
  content: "";
}

.mdi-rotate-left-variant:before {
  content: "";
}

.mdi-rotate-right:before {
  content: "";
}

.mdi-rotate-right-variant:before {
  content: "";
}

.mdi-rounded-corner:before {
  content: "";
}

.mdi-router-wireless:before {
  content: "";
}

.mdi-router-wireless-settings:before {
  content: "難";
}

.mdi-routes:before {
  content: "";
}

.mdi-rowing:before {
  content: "";
}

.mdi-rss:before {
  content: "";
}

.mdi-rss-box:before {
  content: "";
}

.mdi-ruler:before {
  content: "";
}

.mdi-run:before {
  content: "";
}

.mdi-run-fast:before {
  content: "";
}

.mdi-safe:before {
  content: "響";
}

.mdi-sale:before {
  content: "";
}

.mdi-salesforce:before {
  content: "";
}

.mdi-sass:before {
  content: "";
}

.mdi-satellite:before {
  content: "";
}

.mdi-satellite-uplink:before {
  content: "龜";
}

.mdi-satellite-variant:before {
  content: "";
}

.mdi-sausage:before {
  content: "";
}

.mdi-saxophone:before {
  content: "";
}

.mdi-scale:before {
  content: "";
}

.mdi-scale-balance:before {
  content: "";
}

.mdi-scale-bathroom:before {
  content: "";
}

.mdi-scanner:before {
  content: "";
}

.mdi-scanner-off:before {
  content: "契";
}

.mdi-school:before {
  content: "";
}

.mdi-scissors-cutting:before {
  content: "頻";
}

.mdi-screen-rotation:before {
  content: "";
}

.mdi-screen-rotation-lock:before {
  content: "";
}

.mdi-screwdriver:before {
  content: "";
}

.mdi-script:before {
  content: "";
}

.mdi-sd:before {
  content: "";
}

.mdi-seal:before {
  content: "";
}

.mdi-search-web:before {
  content: "";
}

.mdi-seat-flat:before {
  content: "";
}

.mdi-seat-flat-angled:before {
  content: "";
}

.mdi-seat-individual-suite:before {
  content: "";
}

.mdi-seat-legroom-extra:before {
  content: "";
}

.mdi-seat-legroom-normal:before {
  content: "";
}

.mdi-seat-legroom-reduced:before {
  content: "";
}

.mdi-seat-recline-extra:before {
  content: "";
}

.mdi-seat-recline-normal:before {
  content: "";
}

.mdi-security:before {
  content: "";
}

.mdi-security-account:before {
  content: "";
}

.mdi-security-account-outline:before {
  content: "﨑";
}

.mdi-security-close:before {
  content: "鍊";
}

.mdi-security-home:before {
  content: "";
}

.mdi-security-lock:before {
  content: "列";
}

.mdi-security-network:before {
  content: "";
}

.mdi-security-off:before {
  content: "劣";
}

.mdi-select:before {
  content: "";
}

.mdi-select-all:before {
  content: "";
}

.mdi-select-compare:before {
  content: "齃";
}

.mdi-select-drag:before {
  content: "恵";
}

.mdi-select-inverse:before {
  content: "";
}

.mdi-select-off:before {
  content: "";
}

.mdi-selection:before {
  content: "";
}

.mdi-selection-drag:before {
  content: "𤋮";
}

.mdi-selection-off:before {
  content: "";
}

.mdi-send:before {
  content: "";
}

.mdi-send-lock:before {
  content: "";
}

.mdi-serial-port:before {
  content: "";
}

.mdi-server:before {
  content: "";
}

.mdi-server-minus:before {
  content: "";
}

.mdi-server-network:before {
  content: "";
}

.mdi-server-network-off:before {
  content: "";
}

.mdi-server-off:before {
  content: "";
}

.mdi-server-plus:before {
  content: "";
}

.mdi-server-remove:before {
  content: "";
}

.mdi-server-security:before {
  content: "";
}

.mdi-set-all:before {
  content: "";
}

.mdi-set-center:before {
  content: "";
}

.mdi-set-center-right:before {
  content: "";
}

.mdi-set-left:before {
  content: "";
}

.mdi-set-left-center:before {
  content: "";
}

.mdi-set-left-right:before {
  content: "";
}

.mdi-set-none:before {
  content: "";
}

.mdi-set-right:before {
  content: "";
}

.mdi-set-top-box:before {
  content: "咽";
}

.mdi-settings:before {
  content: "";
}

.mdi-settings-box:before {
  content: "";
}

.mdi-settings-helper:before {
  content: "舘";
}

.mdi-settings-outline:before {
  content: "";
}

.mdi-shape:before {
  content: "";
}

.mdi-shape-circle-plus:before {
  content: "";
}

.mdi-shape-outline:before {
  content: "";
}

.mdi-shape-plus:before {
  content: "";
}

.mdi-shape-polygon-plus:before {
  content: "";
}

.mdi-shape-rectangle-plus:before {
  content: "";
}

.mdi-shape-square-plus:before {
  content: "";
}

.mdi-share:before {
  content: "";
}

.mdi-share-outline:before {
  content: "櫓";
}

.mdi-share-variant:before {
  content: "";
}

.mdi-shield:before {
  content: "";
}

.mdi-shield-half-full:before {
  content: "";
}

.mdi-shield-outline:before {
  content: "";
}

.mdi-shield-plus:before {
  content: "龎";
}

.mdi-shield-plus-outline:before {
  content: "﫚";
}

.mdi-shield-remove:before {
  content: "﫛";
}

.mdi-shield-remove-outline:before {
  content: "﫜";
}

.mdi-ship-wheel:before {
  content: "";
}

.mdi-shoe-formal:before {
  content: "ﬢ";
}

.mdi-shoe-heel:before {
  content: "ﬣ";
}

.mdi-shopify:before {
  content: "﫝";
}

.mdi-shopping:before {
  content: "";
}

.mdi-shopping-music:before {
  content: "";
}

.mdi-shovel:before {
  content: "";
}

.mdi-shovel-off:before {
  content: "";
}

.mdi-shower:before {
  content: "烈";
}

.mdi-shower-head:before {
  content: "裂";
}

.mdi-shredder:before {
  content: "";
}

.mdi-shuffle:before {
  content: "";
}

.mdi-shuffle-disabled:before {
  content: "";
}

.mdi-shuffle-variant:before {
  content: "";
}

.mdi-sigma:before {
  content: "";
}

.mdi-sigma-lower:before {
  content: "";
}

.mdi-sign-caution:before {
  content: "";
}

.mdi-sign-direction:before {
  content: "";
}

.mdi-sign-text:before {
  content: "";
}

.mdi-signal:before {
  content: "";
}

.mdi-signal-2g:before {
  content: "";
}

.mdi-signal-3g:before {
  content: "";
}

.mdi-signal-4g:before {
  content: "";
}

.mdi-signal-5g:before {
  content: "﩮";
}

.mdi-signal-cellular-1:before {
  content: "";
}

.mdi-signal-cellular-2:before {
  content: "";
}

.mdi-signal-cellular-3:before {
  content: "";
}

.mdi-signal-cellular-outline:before {
  content: "";
}

.mdi-signal-hspa:before {
  content: "";
}

.mdi-signal-hspa-plus:before {
  content: "";
}

.mdi-signal-off:before {
  content: "";
}

.mdi-signal-variant:before {
  content: "";
}

.mdi-silo:before {
  content: "ﬤ";
}

.mdi-silverware:before {
  content: "";
}

.mdi-silverware-fork:before {
  content: "";
}

.mdi-silverware-fork-knife:before {
  content: "﩯";
}

.mdi-silverware-spoon:before {
  content: "";
}

.mdi-silverware-variant:before {
  content: "";
}

.mdi-sim:before {
  content: "";
}

.mdi-sim-alert:before {
  content: "";
}

.mdi-sim-off:before {
  content: "";
}

.mdi-sina-weibo:before {
  content: "﫞";
}

.mdi-sitemap:before {
  content: "";
}

.mdi-skip-backward:before {
  content: "";
}

.mdi-skip-forward:before {
  content: "";
}

.mdi-skip-next:before {
  content: "";
}

.mdi-skip-next-circle:before {
  content: "";
}

.mdi-skip-next-circle-outline:before {
  content: "";
}

.mdi-skip-previous:before {
  content: "";
}

.mdi-skip-previous-circle:before {
  content: "";
}

.mdi-skip-previous-circle-outline:before {
  content: "";
}

.mdi-skull:before {
  content: "";
}

.mdi-skype:before {
  content: "";
}

.mdi-skype-business:before {
  content: "";
}

.mdi-slack:before {
  content: "";
}

.mdi-slackware:before {
  content: "金";
}

.mdi-sleep:before {
  content: "";
}

.mdi-sleep-off:before {
  content: "";
}

.mdi-smog:before {
  content: "並";
}

.mdi-smoke-detector:before {
  content: "";
}

.mdi-smoking:before {
  content: "";
}

.mdi-smoking-off:before {
  content: "";
}

.mdi-snapchat:before {
  content: "";
}

.mdi-snowflake:before {
  content: "";
}

.mdi-snowman:before {
  content: "";
}

.mdi-soccer:before {
  content: "";
}

.mdi-soccer-field:before {
  content: "";
}

.mdi-sofa:before {
  content: "";
}

.mdi-solar-power:before {
  content: "况";
}

.mdi-solid:before {
  content: "";
}

.mdi-sort:before {
  content: "";
}

.mdi-sort-alphabetical:before {
  content: "";
}

.mdi-sort-ascending:before {
  content: "";
}

.mdi-sort-descending:before {
  content: "";
}

.mdi-sort-numeric:before {
  content: "";
}

.mdi-sort-variant:before {
  content: "";
}

.mdi-soundcloud:before {
  content: "";
}

.mdi-source-branch:before {
  content: "";
}

.mdi-source-commit:before {
  content: "";
}

.mdi-source-commit-end:before {
  content: "";
}

.mdi-source-commit-end-local:before {
  content: "";
}

.mdi-source-commit-local:before {
  content: "";
}

.mdi-source-commit-next-local:before {
  content: "";
}

.mdi-source-commit-start:before {
  content: "";
}

.mdi-source-commit-start-next-local:before {
  content: "";
}

.mdi-source-fork:before {
  content: "";
}

.mdi-source-merge:before {
  content: "";
}

.mdi-source-pull:before {
  content: "";
}

.mdi-soy-sauce:before {
  content: "";
}

.mdi-speaker:before {
  content: "";
}

.mdi-speaker-bluetooth:before {
  content: "說";
}

.mdi-speaker-off:before {
  content: "";
}

.mdi-speaker-wireless:before {
  content: "";
}

.mdi-speedometer:before {
  content: "";
}

.mdi-spellcheck:before {
  content: "";
}

.mdi-spotify:before {
  content: "";
}

.mdi-spotlight:before {
  content: "";
}

.mdi-spotlight-beam:before {
  content: "";
}

.mdi-spray:before {
  content: "";
}

.mdi-spray-bottle:before {
  content: "﫟";
}

.mdi-square:before {
  content: "";
}

.mdi-square-edit-outline:before {
  content: "喇";
}

.mdi-square-inc:before {
  content: "";
}

.mdi-square-inc-cash:before {
  content: "";
}

.mdi-square-medium:before {
  content: "晴";
}

.mdi-square-medium-outline:before {
  content: "﨓";
}

.mdi-square-outline:before {
  content: "";
}

.mdi-square-root:before {
  content: "";
}

.mdi-square-root-box:before {
  content: "廉";
}

.mdi-square-small:before {
  content: "﨔";
}

.mdi-squeegee:before {
  content: "﫠";
}

.mdi-ssh:before {
  content: "";
}

.mdi-stack-exchange:before {
  content: "";
}

.mdi-stack-overflow:before {
  content: "";
}

.mdi-stadium:before {
  content: "";
}

.mdi-stairs:before {
  content: "";
}

.mdi-standard-definition:before {
  content: "";
}

.mdi-star:before {
  content: "";
}

.mdi-star-box:before {
  content: "全";
}

.mdi-star-box-outline:before {
  content: "侀";
}

.mdi-star-circle:before {
  content: "";
}

.mdi-star-circle-outline:before {
  content: "念";
}

.mdi-star-face:before {
  content: "捻";
}

.mdi-star-four-points:before {
  content: "﫡";
}

.mdi-star-four-points-outline:before {
  content: "﫢";
}

.mdi-star-half:before {
  content: "";
}

.mdi-star-off:before {
  content: "";
}

.mdi-star-outline:before {
  content: "";
}

.mdi-star-three-points:before {
  content: "﫣";
}

.mdi-star-three-points-outline:before {
  content: "﫤";
}

.mdi-steam:before {
  content: "";
}

.mdi-steam-box:before {
  content: "奈";
}

.mdi-steering:before {
  content: "";
}

.mdi-steering-off:before {
  content: "懶";
}

.mdi-step-backward:before {
  content: "";
}

.mdi-step-backward-2:before {
  content: "";
}

.mdi-step-forward:before {
  content: "";
}

.mdi-step-forward-2:before {
  content: "";
}

.mdi-stethoscope:before {
  content: "";
}

.mdi-sticker:before {
  content: "";
}

.mdi-sticker-emoji:before {
  content: "";
}

.mdi-stocking:before {
  content: "";
}

.mdi-stop:before {
  content: "";
}

.mdi-stop-circle:before {
  content: "";
}

.mdi-stop-circle-outline:before {
  content: "";
}

.mdi-store:before {
  content: "";
}

.mdi-store-24-hour:before {
  content: "";
}

.mdi-stove:before {
  content: "";
}

.mdi-strava:before {
  content: "ﬥ";
}

.mdi-subdirectory-arrow-left:before {
  content: "";
}

.mdi-subdirectory-arrow-right:before {
  content: "";
}

.mdi-subtitles:before {
  content: "凞";
}

.mdi-subtitles-outline:before {
  content: "猪";
}

.mdi-subway:before {
  content: "";
}

.mdi-subway-variant:before {
  content: "";
}

.mdi-summit:before {
  content: "";
}

.mdi-sunglasses:before {
  content: "";
}

.mdi-surround-sound:before {
  content: "";
}

.mdi-surround-sound-2-0:before {
  content: "";
}

.mdi-surround-sound-3-1:before {
  content: "";
}

.mdi-surround-sound-5-1:before {
  content: "";
}

.mdi-surround-sound-7-1:before {
  content: "";
}

.mdi-svg:before {
  content: "";
}

.mdi-swap-horizontal:before {
  content: "";
}

.mdi-swap-horizontal-variant:before {
  content: "";
}

.mdi-swap-vertical:before {
  content: "";
}

.mdi-swap-vertical-variant:before {
  content: "";
}

.mdi-swim:before {
  content: "";
}

.mdi-switch:before {
  content: "";
}

.mdi-sword:before {
  content: "";
}

.mdi-sword-cross:before {
  content: "";
}

.mdi-symfony:before {
  content: "﫥";
}

.mdi-sync:before {
  content: "";
}

.mdi-sync-alert:before {
  content: "";
}

.mdi-sync-off:before {
  content: "";
}

.mdi-tab:before {
  content: "";
}

.mdi-tab-minus:before {
  content: "ﬦ";
}

.mdi-tab-plus:before {
  content: "";
}

.mdi-tab-remove:before {
  content: "ﬧ";
}

.mdi-tab-unselected:before {
  content: "";
}

.mdi-table:before {
  content: "";
}

.mdi-table-border:before {
  content: "益";
}

.mdi-table-column:before {
  content: "";
}

.mdi-table-column-plus-after:before {
  content: "";
}

.mdi-table-column-plus-before:before {
  content: "";
}

.mdi-table-column-remove:before {
  content: "";
}

.mdi-table-column-width:before {
  content: "";
}

.mdi-table-edit:before {
  content: "";
}

.mdi-table-large:before {
  content: "";
}

.mdi-table-merge-cells:before {
  content: "殮";
}

.mdi-table-of-contents:before {
  content: "";
}

.mdi-table-plus:before {
  content: "充";
}

.mdi-table-remove:before {
  content: "冀";
}

.mdi-table-row:before {
  content: "";
}

.mdi-table-row-height:before {
  content: "";
}

.mdi-table-row-plus-after:before {
  content: "";
}

.mdi-table-row-plus-before:before {
  content: "";
}

.mdi-table-row-remove:before {
  content: "";
}

.mdi-table-search:before {
  content: "癩";
}

.mdi-table-settings:before {
  content: "";
}

.mdi-tablet:before {
  content: "";
}

.mdi-tablet-android:before {
  content: "";
}

.mdi-tablet-cellphone:before {
  content: "簾";
}

.mdi-tablet-ipad:before {
  content: "";
}

.mdi-taco:before {
  content: "";
}

.mdi-tag:before {
  content: "";
}

.mdi-tag-faces:before {
  content: "";
}

.mdi-tag-heart:before {
  content: "";
}

.mdi-tag-minus:before {
  content: "羅";
}

.mdi-tag-multiple:before {
  content: "";
}

.mdi-tag-outline:before {
  content: "";
}

.mdi-tag-plus:before {
  content: "";
}

.mdi-tag-remove:before {
  content: "";
}

.mdi-tag-text-outline:before {
  content: "";
}

.mdi-tape-measure:before {
  content: "ﬨ";
}

.mdi-target:before {
  content: "";
}

.mdi-target-variant:before {
  content: "勇";
}

.mdi-taxi:before {
  content: "";
}

.mdi-teach:before {
  content: "";
}

.mdi-teamviewer:before {
  content: "";
}

.mdi-telegram:before {
  content: "";
}

.mdi-telescope:before {
  content: "﬩";
}

.mdi-television:before {
  content: "";
}

.mdi-television-box:before {
  content: "";
}

.mdi-television-classic:before {
  content: "";
}

.mdi-television-classic-off:before {
  content: "";
}

.mdi-television-guide:before {
  content: "";
}

.mdi-television-off:before {
  content: "";
}

.mdi-temperature-celsius:before {
  content: "";
}

.mdi-temperature-fahrenheit:before {
  content: "";
}

.mdi-temperature-kelvin:before {
  content: "";
}

.mdi-tennis:before {
  content: "";
}

.mdi-tent:before {
  content: "";
}

.mdi-terrain:before {
  content: "";
}

.mdi-test-tube:before {
  content: "";
}

.mdi-test-tube-empty:before {
  content: "蘿";
}

.mdi-test-tube-off:before {
  content: "螺";
}

.mdi-text:before {
  content: "獵";
}

.mdi-text-shadow:before {
  content: "";
}

.mdi-text-short:before {
  content: "令";
}

.mdi-text-subject:before {
  content: "囹";
}

.mdi-text-to-speech:before {
  content: "";
}

.mdi-text-to-speech-off:before {
  content: "";
}

.mdi-textbox:before {
  content: "";
}

.mdi-textbox-password:before {
  content: "";
}

.mdi-texture:before {
  content: "";
}

.mdi-theater:before {
  content: "";
}

.mdi-theme-light-dark:before {
  content: "";
}

.mdi-thermometer:before {
  content: "";
}

.mdi-thermometer-lines:before {
  content: "";
}

.mdi-thermostat:before {
  content: "";
}

.mdi-thermostat-box:before {
  content: "";
}

.mdi-thought-bubble:before {
  content: "";
}

.mdi-thought-bubble-outline:before {
  content: "";
}

.mdi-thumb-down:before {
  content: "";
}

.mdi-thumb-down-outline:before {
  content: "";
}

.mdi-thumb-up:before {
  content: "";
}

.mdi-thumb-up-outline:before {
  content: "";
}

.mdi-thumbs-up-down:before {
  content: "";
}

.mdi-ticket:before {
  content: "";
}

.mdi-ticket-account:before {
  content: "";
}

.mdi-ticket-confirmation:before {
  content: "";
}

.mdi-ticket-outline:before {
  content: "裸";
}

.mdi-ticket-percent:before {
  content: "";
}

.mdi-tie:before {
  content: "";
}

.mdi-tilde:before {
  content: "";
}

.mdi-timelapse:before {
  content: "";
}

.mdi-timer:before {
  content: "";
}

.mdi-timer-10:before {
  content: "";
}

.mdi-timer-3:before {
  content: "";
}

.mdi-timer-off:before {
  content: "";
}

.mdi-timer-sand:before {
  content: "";
}

.mdi-timer-sand-empty:before {
  content: "";
}

.mdi-timer-sand-full:before {
  content: "";
}

.mdi-timetable:before {
  content: "";
}

.mdi-toggle-switch:before {
  content: "";
}

.mdi-toggle-switch-off:before {
  content: "";
}

.mdi-toggle-switch-off-outline:before {
  content: "礼";
}

.mdi-toggle-switch-outline:before {
  content: "神";
}

.mdi-toilet:before {
  content: "寧";
}

.mdi-toolbox:before {
  content: "嶺";
}

.mdi-toolbox-outline:before {
  content: "怜";
}

.mdi-tooltip:before {
  content: "";
}

.mdi-tooltip-edit:before {
  content: "";
}

.mdi-tooltip-image:before {
  content: "";
}

.mdi-tooltip-outline:before {
  content: "";
}

.mdi-tooltip-outline-plus:before {
  content: "";
}

.mdi-tooltip-text:before {
  content: "";
}

.mdi-tooth:before {
  content: "";
}

.mdi-tooth-outline:before {
  content: "";
}

.mdi-tor:before {
  content: "";
}

.mdi-tournament:before {
  content: "玲";
}

.mdi-tower-beach:before {
  content: "";
}

.mdi-tower-fire:before {
  content: "";
}

.mdi-towing:before {
  content: "";
}

.mdi-track-light:before {
  content: "邏";
}

.mdi-trackpad:before {
  content: "";
}

.mdi-trackpad-lock:before {
  content: "爐";
}

.mdi-tractor:before {
  content: "";
}

.mdi-trademark:before {
  content: "勺";
}

.mdi-traffic-light:before {
  content: "";
}

.mdi-train:before {
  content: "";
}

.mdi-train-variant:before {
  content: "";
}

.mdi-tram:before {
  content: "";
}

.mdi-transcribe:before {
  content: "";
}

.mdi-transcribe-close:before {
  content: "";
}

.mdi-transfer:before {
  content: "";
}

.mdi-transit-transfer:before {
  content: "";
}

.mdi-transition:before {
  content: "樂";
}

.mdi-transition-masked:before {
  content: "洛";
}

.mdi-translate:before {
  content: "";
}

.mdi-trash-can:before {
  content: "喝";
}

.mdi-trash-can-outline:before {
  content: "啕";
}

.mdi-treasure-chest:before {
  content: "";
}

.mdi-tree:before {
  content: "";
}

.mdi-trello:before {
  content: "";
}

.mdi-trending-down:before {
  content: "";
}

.mdi-trending-neutral:before {
  content: "";
}

.mdi-trending-up:before {
  content: "";
}

.mdi-triangle:before {
  content: "";
}

.mdi-triangle-outline:before {
  content: "";
}

.mdi-trophy:before {
  content: "";
}

.mdi-trophy-award:before {
  content: "";
}

.mdi-trophy-outline:before {
  content: "";
}

.mdi-trophy-variant:before {
  content: "";
}

.mdi-trophy-variant-outline:before {
  content: "";
}

.mdi-truck:before {
  content: "";
}

.mdi-truck-delivery:before {
  content: "";
}

.mdi-truck-fast:before {
  content: "";
}

.mdi-truck-trailer:before {
  content: "";
}

.mdi-tshirt-crew:before {
  content: "喙";
}

.mdi-tshirt-crew-outline:before {
  content: "";
}

.mdi-tshirt-v:before {
  content: "嗢";
}

.mdi-tshirt-v-outline:before {
  content: "";
}

.mdi-tumble-dryer:before {
  content: "烙";
}

.mdi-tumblr:before {
  content: "";
}

.mdi-tumblr-box:before {
  content: "珞";
}

.mdi-tumblr-reblog:before {
  content: "";
}

.mdi-tune:before {
  content: "";
}

.mdi-tune-vertical:before {
  content: "";
}

.mdi-twitch:before {
  content: "";
}

.mdi-twitter:before {
  content: "";
}

.mdi-twitter-box:before {
  content: "";
}

.mdi-twitter-circle:before {
  content: "";
}

.mdi-twitter-retweet:before {
  content: "";
}

.mdi-two-factor-authentication:before {
  content: "瑩";
}

.mdi-uber:before {
  content: "";
}

.mdi-ubuntu:before {
  content: "";
}

.mdi-ultra-high-definition:before {
  content: "";
}

.mdi-umbraco:before {
  content: "";
}

.mdi-umbrella:before {
  content: "";
}

.mdi-umbrella-closed:before {
  content: "羚";
}

.mdi-umbrella-outline:before {
  content: "";
}

.mdi-undo:before {
  content: "";
}

.mdi-undo-variant:before {
  content: "";
}

.mdi-unfold-less-horizontal:before {
  content: "";
}

.mdi-unfold-less-vertical:before {
  content: "";
}

.mdi-unfold-more-horizontal:before {
  content: "";
}

.mdi-unfold-more-vertical:before {
  content: "";
}

.mdi-ungroup:before {
  content: "";
}

.mdi-unity:before {
  content: "";
}

.mdi-unreal:before {
  content: "聆";
}

.mdi-untappd:before {
  content: "";
}

.mdi-update:before {
  content: "";
}

.mdi-upload:before {
  content: "";
}

.mdi-upload-multiple:before {
  content: "";
}

.mdi-upload-network:before {
  content: "";
}

.mdi-usb:before {
  content: "";
}

.mdi-van-passenger:before {
  content: "";
}

.mdi-van-utility:before {
  content: "";
}

.mdi-vanish:before {
  content: "";
}

.mdi-variable:before {
  content: "﫦";
}

.mdi-vector-arrange-above:before {
  content: "";
}

.mdi-vector-arrange-below:before {
  content: "";
}

.mdi-vector-bezier:before {
  content: "﫧";
}

.mdi-vector-circle:before {
  content: "";
}

.mdi-vector-circle-variant:before {
  content: "";
}

.mdi-vector-combine:before {
  content: "";
}

.mdi-vector-curve:before {
  content: "";
}

.mdi-vector-difference:before {
  content: "";
}

.mdi-vector-difference-ab:before {
  content: "";
}

.mdi-vector-difference-ba:before {
  content: "";
}

.mdi-vector-ellipse:before {
  content: "";
}

.mdi-vector-intersection:before {
  content: "";
}

.mdi-vector-line:before {
  content: "";
}

.mdi-vector-point:before {
  content: "";
}

.mdi-vector-polygon:before {
  content: "";
}

.mdi-vector-polyline:before {
  content: "";
}

.mdi-vector-radius:before {
  content: "";
}

.mdi-vector-rectangle:before {
  content: "";
}

.mdi-vector-selection:before {
  content: "";
}

.mdi-vector-square:before {
  content: "";
}

.mdi-vector-triangle:before {
  content: "";
}

.mdi-vector-union:before {
  content: "";
}

.mdi-venmo:before {
  content: "";
}

.mdi-verified:before {
  content: "";
}

.mdi-vhs:before {
  content: "祥";
}

.mdi-vibrate:before {
  content: "";
}

.mdi-video:before {
  content: "";
}

.mdi-video-3d:before {
  content: "";
}

.mdi-video-4k-box:before {
  content: "";
}

.mdi-video-account:before {
  content: "落";
}

.mdi-video-image:before {
  content: "酪";
}

.mdi-video-input-antenna:before {
  content: "";
}

.mdi-video-input-component:before {
  content: "";
}

.mdi-video-input-hdmi:before {
  content: "";
}

.mdi-video-input-svideo:before {
  content: "";
}

.mdi-video-minus:before {
  content: "鈴";
}

.mdi-video-off:before {
  content: "";
}

.mdi-video-plus:before {
  content: "零";
}

.mdi-video-stabilization:before {
  content: "駱";
}

.mdi-video-switch:before {
  content: "";
}

.mdi-video-vintage:before {
  content: "福";
}

.mdi-view-agenda:before {
  content: "";
}

.mdi-view-array:before {
  content: "";
}

.mdi-view-carousel:before {
  content: "";
}

.mdi-view-column:before {
  content: "";
}

.mdi-view-dashboard:before {
  content: "";
}

.mdi-view-dashboard-outline:before {
  content: "靖";
}

.mdi-view-dashboard-variant:before {
  content: "";
}

.mdi-view-day:before {
  content: "";
}

.mdi-view-grid:before {
  content: "";
}

.mdi-view-headline:before {
  content: "";
}

.mdi-view-list:before {
  content: "";
}

.mdi-view-module:before {
  content: "";
}

.mdi-view-parallel:before {
  content: "";
}

.mdi-view-quilt:before {
  content: "";
}

.mdi-view-sequential:before {
  content: "";
}

.mdi-view-stream:before {
  content: "";
}

.mdi-view-week:before {
  content: "";
}

.mdi-vimeo:before {
  content: "";
}

.mdi-violin:before {
  content: "";
}

.mdi-virtual-reality:before {
  content: "";
}

.mdi-visual-studio:before {
  content: "";
}

.mdi-visual-studio-code:before {
  content: "精";
}

.mdi-vk:before {
  content: "";
}

.mdi-vk-box:before {
  content: "";
}

.mdi-vk-circle:before {
  content: "";
}

.mdi-vlc:before {
  content: "";
}

.mdi-voice:before {
  content: "";
}

.mdi-voicemail:before {
  content: "";
}

.mdi-volleyball:before {
  content: "靈";
}

.mdi-volume-high:before {
  content: "";
}

.mdi-volume-low:before {
  content: "";
}

.mdi-volume-medium:before {
  content: "";
}

.mdi-volume-minus:before {
  content: "";
}

.mdi-volume-mute:before {
  content: "";
}

.mdi-volume-off:before {
  content: "";
}

.mdi-volume-plus:before {
  content: "";
}

.mdi-vote:before {
  content: "羽";
}

.mdi-vote-outline:before {
  content: "﨟";
}

.mdi-vpn:before {
  content: "";
}

.mdi-vuejs:before {
  content: "";
}

.mdi-walk:before {
  content: "";
}

.mdi-wall:before {
  content: "";
}

.mdi-wall-sconce:before {
  content: "亂";
}

.mdi-wall-sconce-flat:before {
  content: "卵";
}

.mdi-wall-sconce-variant:before {
  content: "欄";
}

.mdi-wallet:before {
  content: "";
}

.mdi-wallet-giftcard:before {
  content: "";
}

.mdi-wallet-membership:before {
  content: "";
}

.mdi-wallet-travel:before {
  content: "";
}

.mdi-wan:before {
  content: "";
}

.mdi-washing-machine:before {
  content: "";
}

.mdi-watch:before {
  content: "";
}

.mdi-watch-export:before {
  content: "";
}

.mdi-watch-export-variant:before {
  content: "";
}

.mdi-watch-import:before {
  content: "";
}

.mdi-watch-import-variant:before {
  content: "";
}

.mdi-watch-variant:before {
  content: "";
}

.mdi-watch-vibrate:before {
  content: "";
}

.mdi-water:before {
  content: "";
}

.mdi-water-off:before {
  content: "";
}

.mdi-water-percent:before {
  content: "";
}

.mdi-water-pump:before {
  content: "";
}

.mdi-watermark:before {
  content: "";
}

.mdi-waves:before {
  content: "";
}

.mdi-weather-cloudy:before {
  content: "";
}

.mdi-weather-fog:before {
  content: "";
}

.mdi-weather-hail:before {
  content: "";
}

.mdi-weather-hurricane:before {
  content: "";
}

.mdi-weather-lightning:before {
  content: "";
}

.mdi-weather-lightning-rainy:before {
  content: "";
}

.mdi-weather-night:before {
  content: "";
}

.mdi-weather-partlycloudy:before {
  content: "";
}

.mdi-weather-pouring:before {
  content: "";
}

.mdi-weather-rainy:before {
  content: "";
}

.mdi-weather-snowy:before {
  content: "";
}

.mdi-weather-snowy-rainy:before {
  content: "";
}

.mdi-weather-sunny:before {
  content: "";
}

.mdi-weather-sunset:before {
  content: "";
}

.mdi-weather-sunset-down:before {
  content: "";
}

.mdi-weather-sunset-up:before {
  content: "";
}

.mdi-weather-windy:before {
  content: "";
}

.mdi-weather-windy-variant:before {
  content: "";
}

.mdi-web:before {
  content: "";
}

.mdi-webcam:before {
  content: "";
}

.mdi-webhook:before {
  content: "";
}

.mdi-webpack:before {
  content: "";
}

.mdi-wechat:before {
  content: "";
}

.mdi-weight:before {
  content: "";
}

.mdi-weight-kilogram:before {
  content: "";
}

.mdi-weight-pound:before {
  content: "領";
}

.mdi-whatsapp:before {
  content: "";
}

.mdi-wheelchair-accessibility:before {
  content: "";
}

.mdi-whistle:before {
  content: "例";
}

.mdi-white-balance-auto:before {
  content: "";
}

.mdi-white-balance-incandescent:before {
  content: "";
}

.mdi-white-balance-iridescent:before {
  content: "";
}

.mdi-white-balance-sunny:before {
  content: "";
}

.mdi-widgets:before {
  content: "";
}

.mdi-wifi:before {
  content: "";
}

.mdi-wifi-off:before {
  content: "";
}

.mdi-wifi-strength-1:before {
  content: "爛";
}

.mdi-wifi-strength-1-alert:before {
  content: "蘭";
}

.mdi-wifi-strength-1-lock:before {
  content: "鸞";
}

.mdi-wifi-strength-2:before {
  content: "嵐";
}

.mdi-wifi-strength-2-alert:before {
  content: "濫";
}

.mdi-wifi-strength-2-lock:before {
  content: "藍";
}

.mdi-wifi-strength-3:before {
  content: "襤";
}

.mdi-wifi-strength-3-alert:before {
  content: "拉";
}

.mdi-wifi-strength-3-lock:before {
  content: "臘";
}

.mdi-wifi-strength-4:before {
  content: "蠟";
}

.mdi-wifi-strength-4-alert:before {
  content: "廊";
}

.mdi-wifi-strength-4-lock:before {
  content: "朗";
}

.mdi-wifi-strength-alert-outline:before {
  content: "浪";
}

.mdi-wifi-strength-lock-outline:before {
  content: "狼";
}

.mdi-wifi-strength-off:before {
  content: "郎";
}

.mdi-wifi-strength-off-outline:before {
  content: "來";
}

.mdi-wifi-strength-outline:before {
  content: "冷";
}

.mdi-wii:before {
  content: "";
}

.mdi-wiiu:before {
  content: "";
}

.mdi-wikipedia:before {
  content: "";
}

.mdi-window-close:before {
  content: "";
}

.mdi-window-closed:before {
  content: "";
}

.mdi-window-maximize:before {
  content: "";
}

.mdi-window-minimize:before {
  content: "";
}

.mdi-window-open:before {
  content: "";
}

.mdi-window-restore:before {
  content: "";
}

.mdi-windows:before {
  content: "";
}

.mdi-windows-classic:before {
  content: "蘒";
}

.mdi-wiper:before {
  content: "﫨";
}

.mdi-wordpress:before {
  content: "";
}

.mdi-worker:before {
  content: "";
}

.mdi-wrap:before {
  content: "";
}

.mdi-wrench:before {
  content: "";
}

.mdi-wunderlist:before {
  content: "";
}

.mdi-xamarin:before {
  content: "";
}

.mdi-xamarin-outline:before {
  content: "";
}

.mdi-xaml:before {
  content: "";
}

.mdi-xbox:before {
  content: "";
}

.mdi-xbox-controller:before {
  content: "";
}

.mdi-xbox-controller-battery-alert:before {
  content: "";
}

.mdi-xbox-controller-battery-charging:before {
  content: "﨡";
}

.mdi-xbox-controller-battery-empty:before {
  content: "";
}

.mdi-xbox-controller-battery-full:before {
  content: "";
}

.mdi-xbox-controller-battery-low:before {
  content: "";
}

.mdi-xbox-controller-battery-medium:before {
  content: "";
}

.mdi-xbox-controller-battery-unknown:before {
  content: "";
}

.mdi-xbox-controller-off:before {
  content: "";
}

.mdi-xda:before {
  content: "";
}

.mdi-xing:before {
  content: "";
}

.mdi-xing-box:before {
  content: "";
}

.mdi-xing-circle:before {
  content: "";
}

.mdi-xml:before {
  content: "";
}

.mdi-xmpp:before {
  content: "";
}

.mdi-yahoo:before {
  content: "שׁ";
}

.mdi-yammer:before {
  content: "";
}

.mdi-yeast:before {
  content: "";
}

.mdi-yelp:before {
  content: "";
}

.mdi-yin-yang:before {
  content: "";
}

.mdi-youtube:before {
  content: "";
}

.mdi-youtube-creator-studio:before {
  content: "";
}

.mdi-youtube-gaming:before {
  content: "";
}

.mdi-youtube-tv:before {
  content: "";
}

.mdi-z-wave:before {
  content: "﫩";
}

.mdi-zend:before {
  content: "﫪";
}

.mdi-zip-box:before {
  content: "";
}

.mdi-zip-disk:before {
  content: "諸";
}

.mdi-zodiac-aquarius:before {
  content: "塚";
}

.mdi-zodiac-aries:before {
  content: "墳";
}

.mdi-zodiac-cancer:before {
  content: "奄";
}

.mdi-zodiac-capricorn:before {
  content: "奔";
}

.mdi-zodiac-gemini:before {
  content: "婢";
}

.mdi-zodiac-leo:before {
  content: "嬨";
}

.mdi-zodiac-libra:before {
  content: "廒";
}

.mdi-zodiac-pisces:before {
  content: "廙";
}

.mdi-zodiac-sagittarius:before {
  content: "彩";
}

.mdi-zodiac-scorpio:before {
  content: "徭";
}

.mdi-zodiac-taurus:before {
  content: "惘";
}

.mdi-zodiac-virgo:before {
  content: "慎";
}

.mdi-blank:before {
  content: "";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: #0000008a;
}

.mdi-dark.mdi-inactive:before {
  color: #00000042;
}

.mdi-light:before {
  color: #fff;
}

.mdi-light.mdi-inactive:before {
  color: #ffffff4d;
}

.mdi-rotate-45:before {
  transform: rotate(45deg);
}

.mdi-rotate-90:before {
  transform: rotate(90deg);
}

.mdi-rotate-135:before {
  transform: rotate(135deg);
}

.mdi-rotate-180:before {
  transform: rotate(180deg);
}

.mdi-rotate-225:before {
  transform: rotate(225deg);
}

.mdi-rotate-270:before {
  transform: rotate(270deg);
}

.mdi-rotate-315:before {
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  filter: FlipH;
  -ms-filter: FlipH;
  transform: scaleX(-1);
}

.mdi-flip-v:before {
  filter: FlipV;
  -ms-filter: FlipV;
  transform: scaleY(-1);
}

.mdi-spin:before {
  animation: mdi-spin 2s linear infinite;
}

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

/*# sourceMappingURL=index.a2dd1109.css.map */
